import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const TitleText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.125rem",
    fontWeight: 700,
    color: "#333",
    marginBottom: 8,
    textAlign:'center',
  },
}));
export const ContentText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#333",
    textAlign:'center',
    wordWrap: 'break-word',
  },
}));
export const ActionButton = styled(Button)(() => ({
  "&.MuiButtonBase-root": {
    width:'100%',
    padding: 16,
    background: "#FDD751",
    borderRadius: 8,
    boxSizing: "border-box",
    "& p": {
      fontSize: "0.875rem",
      color: "#333",
      lineHeight: "19.6px",
      fontWeight: 400,
    },
    "&:hover": {
      background: "#FDD751",
    },
  },
}));
