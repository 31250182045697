import React from "react";
import { injectIntl } from "react-intl";
import {BoxIn, TitleText, ContentText} from "./Styled/LogginInStyled";
import {Loader} from "../../common/Styled/CommonStyled";


function LogginIn(props) {
  const { intl } = props;

  return (
      <BoxIn>
        {/*<Loader></Loader>*/}
          <Loader>
          <svg className="spinner" viewBox="0 0 48 48">
              <circle className="path" cx="24" cy="24" r="21.5" fill="none" strokeWidth="5"></circle>
          </svg>
          </Loader>
        <TitleText>POKORO</TitleText>
        <ContentText style={{ whiteSpace: "pre-line" }}>{intl.formatMessage({ id: "login_text" })}</ContentText>
      </BoxIn>
  );
}
export default injectIntl(LogginIn);
