import {
  Box,
  Divider,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({
  width: "100%",
  padding: "0 16px 24px 16px",
  boxSizing: "border-box",
}));

export const TitleBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  '& img':{
    width:65,
    height:65,
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "1rem",
    fontWeight: 700,
  },
}));

export const ContentBox = styled(Box)(() => ({
  marginTop: 16,
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
    fontWeight: 400,
    paddingLeft: 10,
  },
  "& .MuiTypography-subtitle2": {
    color: "#FE536B",
    fontSize: "0.875rem",
    fontWeight: 700,
  },
  "& .MuiTypography-subtitle3": {
    color: "#868AFF",
    fontSize: "0.875rem",
    fontWeight: 700,
  },
  "& ul": {
    padding: "0 0 0 1rem",
    margin: 0,
    "& li": {
      fontSize: "0.75rem",
      marginTop: 10,
    },
  },
}));

export const HashtagBox = styled(Box)(() => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "10px 16px",
  "& > div": {
    fontSize: "0.75rem",
    color: "#212121",
    padding: "8px 16px",
    backgroundColor: "#FDD751",
    borderRadius: 8,
  },
}));
export const DividerLine = styled(Divider)(() => ({
  borderColor: "#F0F1F5",
  margin: "32px 0",
}));

export const ActionBox = styled(Box)(() => ({
  width:'100%',
  display:'flex',
  gap:8,
  '& .MuiTypography-root':{
    paddingLeft:'0 !important'
  },
}));
