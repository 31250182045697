import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";

export const DateTab = styled(Stack)(() => ({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  position:'relative'
}))




