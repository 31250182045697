import React from "react";
import { injectIntl } from "react-intl";
import {Box, Typography} from "@mui/material";

import {DrawerStyle, Puller} from "./Styled/FilterDrawerStyled";
import CommonButton from "../../CommonComponent/CommonButton"
import {styled} from "@mui/material/styles";
import html2canvas from "html2canvas";
import {inject, observer} from "mobx-react";
import jsPDF from "jspdf";
import {DATE_UTIL} from "../../../common/util/date.util";
import {shareFileToAOS} from "../../../nativebridge/JsToNative";

const ActionBox = styled(Box)(() => ({
    display:'flex',
    flexDirection : 'column',
    gap:4,
    marginTop:32,
}));


function FileShareDrawer(props) {
    const {intl, reportStore} = props;
    const {open, onClose, reportRef, setChartView} = props;

    let isPdfDown = false;
    const userAgent = navigator.userAgent.toLowerCase();
    if (!userAgent.includes('samsung')) {
        isPdfDown = true;
        // if (userAgent.includes('safari') || userAgent.includes('chrome')) {
        //     isPdfDown = true;
        // }
    }

    function isAOS() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android/i.test(userAgent);
    }

    const handleClickShare = async (fileType) => {
        reportStore.setShareData('');
        reportStore.setIsReportDownloadLoading(true);
        onClose();

        const prevChartView = props.chartView;
        if (!props.chartView) {
            setChartView(true);
        }

        const reportTitle = `${DATE_UTIL.getTimeZoneDate(reportStore.startDatetime, 'YYYY. MM. DD')} ~ ${DATE_UTIL.getTimeZoneDate(reportStore.endDatetime, 'YYYY. MM. DD')} 리포트`;
        reportStore.setShareFileTitle(`${reportTitle}.${fileType}`);

        let shareData = await generateImage(reportTitle, fileType);

        if (isAOS()) {
            let type = 0;
            if (fileType === 'png') {
                type = 1;
            }
            if (fileType === 'pdf') {
                type = 2;
            }

            const fileName = fileType === 'pdf' ? `${reportTitle}.pdf` : `${reportTitle}.${fileType}`;
            const base64 = await blobToBase64(shareData);

            shareFileToAOS(type, fileName, base64);
        } else {
            reportStore.setShareData(shareData);
        }

        setChartView(prevChartView);
        reportStore.setIsReportDownloadLoading(false);

        if (!isAOS()) {
            reportStore.setShareDialogOpen(true);
        }
    }

    const generateImage = async (reportName, fileType) => {
        const report = reportRef.current;
        if (!report) return;

        const shareBtnEl = document.getElementById('reportShareBtn');

        try {
            if (shareBtnEl) {
                shareBtnEl.style.display = 'none';
            }

            const canvas = await html2canvas(report,{
                scrollY: 0
            });
            const imageData = cropCanvas(canvas, 0, 0, canvas.width, canvas.height, fileType === 'pdf' ? 'jpeg' : fileType);

            let shareData = {title: `${reportName}.${fileType}`, thumbnail: imageData};
            let blob = '';

            if (fileType !== 'pdf') {
                blob = await fetch(imageData).then(res => res.blob());
                shareData.files = [new File([blob], `${reportName}.${fileType}`, {type: `image/${fileType}`})];
            } else {
                const imgWidth = 210;
                const pageHeight = imgWidth * 1.414;
                const imgHeight = canvas.height * imgWidth / canvas.width;
                let heightLeft = imgHeight;

                const doc = new jsPDF('p', 'mm');
                let position = 0;

                doc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;

                while (heightLeft >= 20) {
                    position = heightLeft - imgHeight;
                    doc.addPage();
                    doc.addImage(imageData, 'PNG', 0, position, imgWidth, imgHeight);
                    heightLeft -= pageHeight;
                }

                blob = doc.output('blob');
                shareData.files = [new File([blob], `${reportName}.pdf`, {type: 'application/pdf'})];
            }

            if (shareBtnEl) {
                shareBtnEl.style.display = 'inline-flex';
            }

            if (isAOS()) {
                return blob;
            }

            return shareData;
        } catch (error) {
            console.error('GenerateImage Error:', error);
        } finally {
            if (shareBtnEl) {
                shareBtnEl.style.display = 'inline-flex';
            }
        }
    };

    const blobToBase64 = (blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            reader.onloadend = () => {
                resolve(reader.result.split(',')[1]);
            };

            reader.onerror = reject;

            reader.readAsDataURL(blob);
        });
    }

    const cropCanvas = (sourceCanvas, startX, startY, width, height, fileType) => {
        const targetCanvas = document.createElement('canvas');
        targetCanvas.width = width;
        targetCanvas.height = height;
        const context = targetCanvas.getContext('2d');
        context.drawImage(sourceCanvas, startX, startY, width, height, 0, 0, width, height);
        return targetCanvas.toDataURL(`image/${fileType}`);
    };

  return (
      <DrawerStyle
          anchor="bottom"
          open={open}
          onClose={onClose}
          style={{zIndex:9999}}
      >
          <Puller />

          <Typography variant='h5'>{intl.formatMessage({ id: "share_type" })}</Typography> {/* 공유할 파일 유형 선택 */}
          <Typography variant='h6'>{intl.formatMessage({ id: "share_type_select" })}</Typography> {/* 내보내기 및 공유할 리포트의 유형을 선택해주세요. */}
          <ActionBox>
              <CommonButton
                  // disabled={'disabled'}
                  border={'#C9C9CA'}
                  background={'#fff'}
                  color={'#333'}
                  text={'JPG'}
                  onClick={() => handleClickShare('jpeg')}
              />
              <CommonButton
                  // disabled={'disabled'}
                  border={'#C9C9CA'}
                  background={'#fff'}
                  color={'#333'}
                  text={'PNG'}
                  onClick={() => handleClickShare('png')}
              />
              {isPdfDown &&
                  <CommonButton
                      // disabled={'disabled'}
                      border={'#C9C9CA'}
                      background={'#fff'}
                      color={'#333'}
                      text={'PDF'}
                      onClick={() => handleClickShare('pdf')}
                  />
              }
          </ActionBox>
      </DrawerStyle>
  );
}

export default injectIntl(inject('reportStore')(observer(FileShareDrawer)));