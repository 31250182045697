import { Badge, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../../common/Styled/CommonStyled";

export const Root = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  "& .MuiTypography-root": {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
  },
}));
export const CardBoxStyle = styled(CardBox)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  margin: 0,
  borderRadius: 24,
  padding:'1rem 0',
  width: '100%',
  "&:hover": {
    cursor: "pointer",
  },
  "-webkit-tap-highlight-color": "transparent",
}));
export const BadgeStyle = styled(Badge)(() => ({
  "& .MuiAvatar-root": {
    width: 30,
    height: 30,
  },
  "& .MuiBadge-badge": {
    backgroundColor: "#FE536B",
    minWidth: 6,
    height: 6,
    left: 0,
    right: "unset",
  },
}));
export const TitleBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  marginBottom: 8,
  padding: '0 1rem',
}));
export const ProgressBox = styled(Box)(()=>({
  padding: "0.5rem",
  gap: "1rem",
  marginTop: "0.5rem",
  maxWidth:"700px"
}))
export const ListTitle = styled(Typography)(() => ({
  fontSize: "1rem",
  fontWeight: 700,
  color: "#333",
}));
export const DateTextStyle = styled(Typography)(() => ({
  fontSize: "0.75rem",
  color: "#909195",
}));
export const ListContent = styled(Box)(() => ({
  fontSize: "0.875rem",
  '& .scoreTitle':{
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 1rem',
    ' .score': {
      display: 'flex',
      alignItems: 'center',
      gap: '0.25rem'
    },
    '& svg':{
      fontSize:'16px'
    }
  }
}));

