import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

export const LoadingBox = styled(Box)(() => ({
  margin: "25px 0",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& .CircularProgressbar": {
    width: 50,
  },
  "& .CircularProgressbar-trail": {
    stroke: "#F0F1F5",
  },
  "& .CircularProgressbar-path": {
    stroke: "#FDD751",
  },
  "& .CircularProgressbar-text": {
    fontSize: "1.625rem",
    fill: "#909195",
    dominantBaseline: "central",
    fontWeight: 500,
  },
}));

export const ErrorText = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "0.75rem",
    fontWeight: 400,
    lineHeight: "16.8px",
    textAlign: "center",
    color: "#FE536B",
  },
}));
