import React from 'react';
import ApexCharts from 'react-apexcharts';
import { Stack, Box, Typography } from '@mui/material';

const DonutChart = ({ options, series, labels, colors, totalLabel, labelFormatter }) => {
    // 기본 회색 도넛 차트 설정
    const defaultChartOptions = {
      series: [1],
      chart: {
        type: 'donut',
        width: '100%',
        height: '400px',
      },
      colors: ['#ddd'],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '55%',
            labels: {
              show: true,
              name: {
                show: false,
              },
              value: {
                show: false,
              },
              total: {
                show: false,
              },
            },
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      legend: { show: false },
    };
  const chartOptions = {
    series: series,
    labels: labels,
    chart: {
      type: 'donut',
      width: '100%',
      height: '400px',
    },
    colors: colors,
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '55%',
          color: '#ddd',
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: '14px',
              fontWeight: 600,
              offsetY: -10,
            },
            value: {
              show: true,
              fontSize: '24px',
              fontWeight: 700,
              offsetY: 0,
            },
            total: {
              show: true,
              showAlways: true,
              label: totalLabel,
              color: '#373d3f',
              fontSize: '14px',
              formatter: function (w) {
                const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                return total + ' ' + '회';
              },
            },
          },
        },
      },
    },
    states: {
      hover: {
        filter: 'none',
      },
      active: {
        filter: 'none',
      },
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: true,
      formatter: labelFormatter,
      style: {
        colors: ['#fff'],
        textShadow: 'none',
      },
    },
    legend: { show: false },
  };

  return (
    <Stack alignItems="center" gap={2} position='relative' >
      <ApexCharts options={defaultChartOptions} series={[1]} type="donut" style={{position:'absolute', top:'0px'}}/>
      <ApexCharts options={chartOptions} series={series} type="donut" />
      <Stack gap={0.5} justifyContent='space-between' width='100%' maxWidth={500}>
        {labels.map((label, index) => (
          <Stack key={index} direction="row" alignItems="center" justifyContent='space-between'>
            <Stack gap={0.5} direction="row" alignItems="center">
              <Box sx={{ width: '12px', height: '12px', bgcolor: colors[index], borderRadius: '50%' }} />
              <Typography variant='body2'>{label}</Typography>
            </Stack>
            <Typography variant='body1'>{series[index]}</Typography>
        </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default DonutChart;
