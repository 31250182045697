import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useHistory } from "react-router-dom"; // useHistory와 useLocation 추가
import { Typography, Stack, Box } from "@mui/material";
import CommonBack from "../CommonComponent/CommonBack";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import { TitleBox, List } from "./Styled/LanguageStyled";
import { inject, observer } from "mobx-react";

import { usePenStatus } from "../../nativebridge/PenStatus";
import { customSendSetting } from "../../nativebridge/JsToNative";
import CommonLoading from "../CommonComponent/CommonLoading";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";

import {
  NativeToJsBridge,
  NativeToJsEventName,
  getPlatform,
} from "../../nativebridge/NativeToJs";

// import languageMap from "./Setting";

function NetworkRegionSetting(props) {
  const { intl } = props;

  const history = useHistory();
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [loading, setLoading] = useState(false);
  const [disconnect, setDisconnect] = React.useState(false);
  const [agentServerList, setAgentServerList] = useState([]);
  const handleBack = () => {
    history.goBack();
  };
  const handleGoSetting = () => {
    history.push("/setting"); // setting 페이지로 돌아가기
  };
  

  const onDeviceDisConnected = () => {
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
    handleBack();
  };

  const handleServerChange = (serverName, serverUrl) => {
    console.log(`handleServerChange serverName=${serverName}, serverUrl=${serverUrl}`);

    if (globalPenStatus.serverInfo.name !== serverName) {
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        serverInfo: {
          ...prevStatus.serverInfo, // spread the previous serverInfo object
          name: serverName, // update serverName
          url: serverUrl, // update serverName
        },
      }));
      const settingData = {
        messageType: "POKORO2_Settings",
        payload: {
          server_name: serverName,
          server_url: serverUrl,
        },
      };
      setLoading(true);
      customSendSetting(JSON.stringify(settingData));
    }
  };



  useEffect(() => {
    // if (getPlatform() === "Web") {
    //   setGlobalPenStatus((prevStatus) => ({
    //     ...prevStatus,
    //     serverInfo: {
    //       name: "Korea",
    //       url: "pokoro-dev.onthe.live:6444",
    //     }
    //   }));
    // }

    console.log(
      "====_AGENT_SERVER_LIST_: " + localStorage.getItem("_AGENT_SERVER_LIST_")
    );
    console.log(globalPenStatus);
    const serverList = localStorage.getItem("_AGENT_SERVER_LIST_");
    if (serverList) {
      setAgentServerList(JSON.parse(serverList));
    }

    console.log(globalPenStatus.deviceInfo.lang_speaking);
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        // const jsonStr = event;
        onDeviceDisConnected();
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        setLoading(false);
      }
    );
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceDisConnected
      );
    };
  }, []);



  return (
    <>
      <Stack width="100%">
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999, // 충분히 큰 z-index로 다른 요소들 위에 올리기
            }}
          >
            <CommonLoading text={intl.formatMessage({ id: "connecting_to_wifi" })} />
          </div>
        )}
        <CommonComfirmDialog
          open={disconnect}
          title={intl.formatMessage({ id: "disconnect_dialog_Title" })} /* 포코로 연결 종료 */
          contents={intl.formatMessage({ id: "disconnect_dialog_Title" })} /* 포코로 연결 종료 */
          buttonText={intl.formatMessage({ id: "dialog_confirmButton" })} 
          onClick={handleGoSetting}
        />
        <TitleBox>
          <CommonBack onClick={handleBack} />
          <SubpageTitle sx={{ background: "unset" }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
              {intl.formatMessage({ id: "select_region" })} {/* 네트워크 지역 선택*/}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage({ id: "select_region_txt" })} {/* 포코로를 사용할 지역을 선택해주세요. */}
              {/*               <div
                style={{ color: "#909195", fontSize: "0.75rem", marginTop: 4 }}
              >
                {intl.formatMessage({ id: "system_voice_instruction" })}
              </div> */}
            </Typography>
          </SubpageTitle>
        </TitleBox>
        <Stack pt={4} gap={1}>
          {agentServerList.map((server) => (
            <List
              key={server.name}
              selected={globalPenStatus.serverInfo.name === server.name} // 선택된 국가 코드 확인
              onClick={() => handleServerChange(server.name, server.serverUrl)} // 국가 코드 설정
            >
              {server.name}{" "}
            </List>
          ))}
          {/* <List selected>ASIA</List>
          <List>AMERICA</List>
          <List>EUROPE</List> */}
        </Stack>
      </Stack>
    </>
  );
}

export default injectIntl(
  inject("authStore")(observer(NetworkRegionSetting))
);
