import { Box, Stack} from "@mui/material";
import { styled } from "@mui/material/styles"


export const Header = styled(Box)(() => ({
    textAlign: 'left',
    '& h3': {
      fontSize: '1.125rem',
      display:'flex',
      gap: '8px',
    },
    '& h4': {
      fontSize: '1rem',
      display:'flex',
      alignItems: 'center',
      gap: '4px',
      '& svg': {
        fontSize: '1.25rem'
      }
    }
  }));


  export const ReportBox = styled(Stack)(() => ({
    gap: '1rem',
    background: '#fff', 
    padding: '16px', 
    boxShadow: '0 1px 2px rgba(0, 0, 0, .05)' 
  }));