import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const CircularProgressbarComponent = ({ value, text, pathColor }) => {
  
  return (
    <CircularProgressbar
      value={value}
      text={`${text}`}
      strokeWidth={15}
      styles={buildStyles({
        textSize: '1.5rem',
        pathColor: pathColor,
        textColor: '#000',
        trailColor: '#f0f1f5',
        pathTransition: 'stroke-dashoffset 1.5s ease 0s',
        pathTransitionDuration: 1.5,
      })}
    />
  );
};

export default CircularProgressbarComponent;
