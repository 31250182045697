import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../../common/Styled/CommonStyled";
export const Root = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  paddingBottom: 64,
  "& .MuiTypography-root": {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
  },
}));

export const ButtonBack = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "flex-start",
  padding: "24px 16px",
  boxSizing: "border-box",
  "& .MuiTypography-root": {
    fontSize: "0.875rem",
    color: "#333",
  },
  "&:hover": {
    background: "transparent",
  },
}));

export const WrapBox = styled(Box)(() => ({
  padding: "0 16px 24px",
}));
export const CardBoxViewer = styled(CardBox)(() => ({
  margin: 0,
  padding: "24px 16px",
}));
export const ChipBox = styled(Box)(() => ({
  display: "flex",
  gap: 8,
  marginBottom: 8,
  "& .MuiChip-root": {
    height: 19,
    lineHeight: 1,
    "& .MuiChip-label": {
      padding: "4px 16px",
      fontSize: "0.65rem",
      color: "#fff",
    },
  },
}));

export const BoxTitle = styled(Box)(() => ({
  "& .MuiTypography-root": {
    textAlign: "left",
  },
}));

export const ListBox = styled(Box)(() => ({
  marginTop: 16,
  "& ul": {
    padding: "0 0 0 24px",
    margin: 0,
  },
  "& li": {
    fontSize: "0.875rem",
    color: "#909195",
    marginBottom: 3,
  },
}));

export const ChatBox = styled(Box)(() => ({
  padding: "24px 0",
  display: "flex",
  flexDirection: "column",
  gap: 10,
  "& > div": {
    display: "flex",
    "& > span": {
      display: "flex",
      flexDirection:'column',
      borderRadius: 24,
      padding: 10,
      maxWidth: "80%",
      fontSize: "0.875rem",
      color: "#333",
    },
  },
}));
export const AnswerBox = styled(Box)(() => ({
  justifyContent: "flex-start",
  "& > span": {
    backgroundColor: "rgb(253 215 78 / 20%)",
  },
}));
export const QuestionBox = styled(Box)(() => ({
  justifyContent: "flex-end",
  "& > span": {
    backgroundColor: "rgb(133 137 255 / 20%)",
  },
}));
