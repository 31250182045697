import { useLocation } from "react-router";
import React, { useEffect, useState } from "react";

import { inject, observer } from "mobx-react";
import ConversationList from "./ConversationList";
import ConversationContentDetailComponent from "./ConversationContentDetailComponent";
import BottomNavigation from "../BottomNavigation";
import { Root } from "../../../common/Styled/CommonStyled";
import { isConversation } from "../../../nativebridge/JsToNative";

function Conversation(props) {
  const [tabValue, setTabValue] = useState("conversation");
  const { conversationStore } = props;
  const { location } = useLocation();

  useEffect(() => {
    isConversation(true);
    return () => {
      isConversation(false);
    };
  }, []);
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <Root>
      {tabValue === "conversation" && (
        <>
          <React.Fragment>
            {conversationStore.type === "list" ? (
              <ConversationList />
            ) : (
              <ConversationContentDetailComponent />
            )}
          </React.Fragment>
        </>
      )}
      <BottomNavigation
        value={tabValue}
        handleChangeTab={(event, newValue) => setTabValue(newValue)}
      />
    </Root>
  );
}

export default inject("conversationStore")(observer(Conversation));
