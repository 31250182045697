import React from "react";

import { BoxIn } from "./Styled/CommonEmptyPoKoRoStyled";

export default function CommonBack(props) {
  const { image, text } = props;

  return (
    <BoxIn>
      <img src={image} alt={"이미지"} />
      {text}
    </BoxIn>
  );
}
