import axios from 'axios';

const DefaultContentType = 'application/json';
export class Repository {
    getRequestPromise = (method, url, data, contentType = DefaultContentType) => {
        return new Promise((resolve, reject) => {
            const config = {
                method: method,
                url: url,
                data: data,
            };
            axios
                .request(config)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                });
        });
    };
}
