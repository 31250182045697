import { Box, IconButton } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BackBox = styled(Box)(() => ({
  width: "100%",
  padding: "8px 16px 8px 10px",
  boxSizing: "border-box",
  display: "flex",
}));

export const IconButtonStyle = styled(IconButton)(() => ({
  "&.MuiButtonBase-root": {
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
}));
