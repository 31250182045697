import {makeAutoObservable} from "mobx";


const logPrefix  = "[ConversationStore]"
export default class ConversationStore {

    constructor(props) {
        this.conversationRepository = props.conversationRepository;

        this.isConversationListLoading = false;
        this.isConversationLoading = false;

        this.type = 'list';
        this.conversationCount = 0;
        this.conversationList = [];
        this.conversation = '';
        this.tempSearchKeyword = '';
        this.searchKeyword = '';
        this.searchSortDirection = 'descending';
        this.rowsPerPage = 10;
        this.page = 1;
        this.topScrollMessage = '';

        makeAutoObservable(this);
    }

    init = () => {
        this.type = 'list';
        this.conversationCount = 0;
        this.conversationList = [];
        this.tempSearchKeyword = '';
        this.searchKeyword = '';
        this.searchSortDirection = 'descending';
        this.rowsPerPage = 10;
        this.page = 1;
        this.topScrollMessage = '';
    }

    setType = (type) => {
        this.type = type;
    }

    setTempSearchKeyword = (keyword) => {
        this.tempSearchKeyword = keyword;
    }

    setSearchKeyword = (keyword) => {
        this.searchKeyword = keyword;
    }

    setSearchSortDirection = (direction) => {
        this.page = 1;
        this.searchSortDirection = direction;
    }

    setPage = (page) => {
        this.page = page;
    }

    setTopScrollMessage = (message) => {
        this.topScrollMessage = message;
    }

    * getConversationList(userId, deviceId, isNextPage, callback) {
        try {
            console.log(logPrefix, 'Start getConversationList.');

            if (isNextPage) {
                this.page = this.page + 1;
            } else {
                this.isConversationListLoading = true;
                this.conversationList = [];
                this.page = 1;
            }

            const response = yield this.conversationRepository.getConversationList(userId, deviceId, this.searchKeyword, this.rowsPerPage, this.page, this.searchSortDirection);

            if (isNextPage) {
                this.conversationList = this.conversationList.concat(response.data.dialogList);
            } else {
                this.conversationList = response.data.dialogList;
            }
            
            this.isConversationListLoading = false;
            console.log(logPrefix, 'Finished getConversationList.');

            callback && callback();
        } catch(e) {
            this.conversationList = '';
            console.log(logPrefix, 'Failed getConversationList. error: ' + e);
        } finally {
            this.isConversationListLoading = false;
        }
    }

    * getConversation(userId, deviceId, dialogId, callback) {
        try {
            console.log(logPrefix, 'Start getConversation.');
            this.conversation = '';
            this.isConversationLoading = true;

            const response = yield this.conversationRepository.getConversation(userId, deviceId, dialogId);

            this.conversation = response.data;

            this.isConversationLoading = false;
            console.log(logPrefix, 'Finished getConversation.');

            callback && callback();
        } catch(e) {
            this.conversation = '';
            console.log(logPrefix, 'Failed getConversation. error: ' + e);
        } finally {
            this.isConversationLoading = false;
        }
    }

    * getLatestRealTimeConversation(userId, deviceId, dialogId) {
        try {
            console.log(logPrefix, 'Start getLatestRealTimeConversation.');

            const againRequestDialogIdList = this.conversation.dialogList
                .filter(item => {
                    const pronunciation = item.pronunciation;
                    if (!pronunciation) return true;
                    if (pronunciation.wordList.length === 0) return true;
                    return pronunciation.wordList.some(word => word.phonemeList.length === 0);
                })
                .map(item => item.id);

            const lastId = Math.max(...this.conversation.dialogList.map(obj => obj.id));
            const response = yield this.conversationRepository.getRealTimeConversation(userId, deviceId, dialogId, lastId, againRequestDialogIdList);

            response.data = response.data.filter(newItem => {
                const oldIndex = this.conversation.dialogList.findIndex(oldItem => oldItem.id === newItem.id);

                if (oldIndex !== -1) {
                    this.conversation.dialogList[oldIndex] = newItem;
                    return false;
                }
                return true;
            });


            this.conversation.dialogList.push(...response.data);

            console.log(logPrefix, 'Finished getLatestRealTimeConversation.');
        } catch(e) {
            console.log(logPrefix, 'Failed getLatestRealTimeConversation. error: ' + e);
        }
    }
}
