import {makeAutoObservable} from "mobx";
import languageDataKo from '../language/languageDataKo.json';
import languageDataEn from '../language/languageDataEn.json';
import languageDataJa from '../language/languageDataJa.json';
import languageDataZh from '../language/languageDataZh.json';
import {DATE_UTIL} from "../common/util/date.util";

const languageData = {
    'ko': languageDataKo,
    'en': languageDataEn,
    'ja': languageDataJa,
    'zh': languageDataZh,
};

export const LocalStorageLanguageKey = '_POKORO_BOARD_LANG';
const DefaultLanguage = 'en';

export default class LanguageStore {
    constructor() {
        this.language = DefaultLanguage;
        this.languageData = languageData[DefaultLanguage];

        const language = this.getBrowserLanguage();
        this.setLanguage(language);

        makeAutoObservable(this);
    }

    getBrowserLanguage() {
        const language = localStorage.getItem(LocalStorageLanguageKey) || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage || navigator.browserLanguage || 'en';
        return language.toLowerCase().split(/[_-]+/)[0];
    };

    setLanguage(language) {
        if(languageData[language]) {
            this.language = language;
            this.languageData = languageData[language];
            localStorage.setItem(LocalStorageLanguageKey, language);
        } else {
            console.warn(`Language ${language} does not supported : set to default ${DefaultLanguage}`);

            this.language = DefaultLanguage;
            this.languageData = languageData[DefaultLanguage];
            localStorage.setItem(LocalStorageLanguageKey, DefaultLanguage);
        }
        DATE_UTIL.setLanguage(this.language);
    }

    get getDisplayLanguage() {
        switch (this.language) {
            case "ko": return "한국어";
            case "ja": return "日本語";
            case "zh": return "简体中文";
            default: return "English"
        }
    };

    get getLanguageString() {
        switch (this.language) {
            case "ko": return "Korean";
            case "ja": return "Japanese";
            case "zh": return "Chinese";
            default: return "English"
        }
    };
}