import {
  Box,
  Button,
  Stack,
  SwipeableDrawer,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(Box)(() => ({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#FDD751",
  padding: '8px 16px',
  boxSizing: "border-box",
  zIndex: 1300,
  "& > div": {
    display: "flex",
    alignItems: "center",
  },
}));

export const SelectButton = styled(Button)(() => ({
  color: "#333",
  fontSize: "0.75rem",
  "& > svg": {
    marginLeft: 16,
  },
}));




export const DrawerStyle = styled(SwipeableDrawer)(() => ({
  "& .MuiDrawer-paper": {
    top: 53,
    padding: "24px 16px 16px",
    borderRadius: "0 0 30px 30px",
    backgroundColor: "#FFFCF0",
  },
}));

export const DrawerTitle = styled(Box)(() => ({
  "& .MuiTypography-root": {
    color: "#333",
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "1.125rem",
    fontWeight: 700,
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
  },
}));

export const DrawerContents = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 8,
  marginTop: 16,
  marginBottom: 8,
}));
export const Caption = styled(Typography)(() => ({
  fontSize: "0.75rem",
  textAlign: "right",
  color: "#909195",
}));
export const IdBox = styled(Button)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  gap: 8,
  width: "100%",
  padding: 16,
  border: "2px solid #F0F1F5",
  borderRadius: 8,
  backgroundColor: "#fff",
  boxSizing: "border-box",
  "&.selected": {
    backgroundColor: "#fff",
    borderColor: "#FDD751",
  },
  "&:hover, &:focus, &.selected": {
    backgroundColor: "#fff",
  },
  "& .MuiTypography-body2": {
    color: "#333",
  },
  "& .MuiTypography-caption": {
    color: "#909195",
  },
}));

export const Puller = styled("div")(() => ({
  width: 32,
  height: 2,
  backgroundColor: "#909195",
  borderRadius: 20,
  margin: "24px auto 0",
}));
