import React from 'react';
import { Box } from '@mui/material';
import ReactWordcloud from 'react-wordcloud';
import { injectIntl } from 'react-intl';

const WordCloudComponent = ({ wordList = [], intl }) => {
  const isEmptyData = wordList.length === 0;

  // 임시 데이터 생성
  const placeholderData = [
    { word: 'Sample', count: 20 },
    { word: 'word', count: 30 },
    { word: 'Apple', count: 24 },
    { word: 'Bear', count: 13 },
    { word: 'Dinosor', count: 2 },
    { word: 'Pokoro', count: 34 },
    { word: 'Math', count: 11 },
    { word: 'Ghost', count: 27 },
    { word: 'Computer', count: 32 },
    { word: 'Mouse', count: 33 },
    { word: 'Cat', count: 9 },
    { word: 'Book', count: 25 },
    { word: 'Snack', count: 18 },
    { word: 'Freind', count: 10 },
    { word: 'Baseball', count: 5 },
  ];

  const dataToUse = isEmptyData ? placeholderData : wordList;

  const options = {
    rotations: 2, // 회전 수
    rotationAngles: [0], // 회전 각도
    scale: "sqrt", // 단어 크기 조정 방법
    fontSizes: [20, 80], // 최소 및 최대 폰트 크기
    enableTooltip: false, // 툴팁 비활성화
  };

  return (
    <Box position='relative'>
      {isEmptyData && (
      <div
        style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        textAlign: 'center'
      }}>
        <pre>{intl.formatMessage({ id: 'no_data_word' })}</pre> {/* 오늘 대화한 내역이 없어요. 내일은 우리 꼭 만나요! */}
      </div>
      )}
      <Box>
        {dataToUse.length > 0 ? (
          <ReactWordcloud
            words={dataToUse.map((item) => {
              return { text: item.word, value: item.count };
            })}
            options={options}
            callbacks={{
              getWordTooltip: () => null, // 툴팁 비활성화
              onWordMouseOver: () => null, // 마우스 오버 시 이벤트 비활성화
              onWordMouseOut: () => null, // 마우스 아웃 시 이벤트 비활성화
            }}
            style={{border: isEmptyData ?'1px solid #aaa':'', borderRadius:isEmptyData ?'10px':'', opacity: isEmptyData ? 0.1 : 1 }}
          />
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
};

export default injectIntl(WordCloudComponent);
