import React, {useEffect} from "react";
import { injectIntl } from "react-intl";
import { Box, FormControl, Typography } from "@mui/material";
import { ReactComponent as Refresh } from "../../../common/Icons/Refresh.svg";
import { ReactComponent as Search } from "../../../common/Icons/Search.svg";
import { ReactComponent as SearchErrorIcon } from "../../../common/Icons/SearchErrorIcon.svg";
import EmptyImage from "../../../common/Icons/EmptyImage.svg";
import PoKoRoSadImage from "../../../common/Images/PoKoRoSadImage.png";
import QuestionPokoro from "../../../common/Icons/QuestionPokoro.svg";
import CommonEmptyPoKoRo from "../../CommonComponent/CommonEmptyPoKoRo";
import { SearchIconButton } from "../../CommonComponent/Styled/CommonInputStyled";
import ConversationContentComponent from "./ConversationContentComponent";
import {
  ButtonIconStyle,
  CommonInputStyle,
  ControlBox,
  MenuItemStyle,
  SelectStyle,
  ButtonText,
  ErrorBox,
} from "./Styled/ConversationListStyled";
import { TitleStyle } from "../../../common/Styled/CommonStyled";
import {ReportBox} from '../Styled/SubPageStyled'
import {inject, observer} from "mobx-react";
import { ReactComponent as KeyboardArrowDownIcon } from "../../../common/Icons/ChevronDown.svg";
import CommonLoading from "../../CommonComponent/CommonLoading";
import { Icon } from '@iconify/react';


// const SelectArrowIcon = () => {
//   return <ChevronDown width={"48px"} style={{pointerEvents: 'none !important'}}/>;
// };

function ConversationList(props) {
    const {intl, authStore, userStore, conversationStore} = props;

    useEffect(() => {
        conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getConversationList = () => {
        conversationStore.getConversationList(authStore.loginUser.id, userStore.selectedDevice.deviceId, false);
    }

    const handleChangeSortDirection = (event) => {
        conversationStore.setSearchSortDirection(event.target.value);
        getConversationList();
    };

    const handleChangeSearchKeyword = (e) => {
        conversationStore.setTempSearchKeyword(e.target.value);
    }

    const handleKeyPressSearch = (e) => {
        e.preventDefault();
        if (e.keyCode === 13) {
            conversationStore.setSearchKeyword(conversationStore.tempSearchKeyword);
            conversationStore.setPage(1);
            getConversationList();
        }
    }

    return (
      <>
        <TitleStyle>
          <Icon icon="emojione:spiral-notepad" /> {intl.formatMessage({ id: "conversation_list" })}
        </TitleStyle>
        <Box px={2} mb={2}>
          <CommonInputStyle
            label=""
            variant="outlined"
            fullWidth
            placeholder={intl.formatMessage({ id: "search_placeholder" })}
            value={conversationStore.tempSearchKeyword}
            onChange={handleChangeSearchKeyword}
            onKeyUp={handleKeyPressSearch}
            InputProps={{
              startAdornment: (
                <SearchIconButton position="start">
                  <Search />
                </SearchIconButton>
              ),
            }}
          />
        </Box>
        <ControlBox px={2}>
          <FormControl variant="standard">
            <SelectStyle
              value={conversationStore.searchSortDirection}
              onChange={handleChangeSortDirection}
              label="SortDirection"
              displayEmpty
              IconComponent={() => (
                <KeyboardArrowDownIcon style={{
                  top: '2px',
                  left: '34px',
                  display: 'inline-block',
                  pointerEvents: 'none'
                }} />
              )}
            >
              <MenuItemStyle value="descending"><pre>{intl.formatMessage({ id: "sort_latest" })}</pre></MenuItemStyle> {/* 최신순 */}
              <MenuItemStyle value="ascending"><pre>{intl.formatMessage({ id: "sort_ascending" })}</pre></MenuItemStyle> {/* 과거순 */}
            </SelectStyle>
          </FormControl>
          <ButtonIconStyle disableRipple onClick={getConversationList}>
            <Typography>{intl.formatMessage({ id: "refresh" })}</Typography> <Refresh /> {/* 새로고침 */}
          </ButtonIconStyle>
        </ControlBox>
        <ReportBox>
          {conversationStore.isConversationListLoading ?
            <CommonLoading text={intl.formatMessage({ id: "loading_search_results" })} /> //검색 결과를 로딩 중입니다. 잠시만 기다려주세요.
            :
            <>
              {userStore.selectedDevice === '' ?
                <CommonEmptyPoKoRo
                  image={EmptyImage}
                  text={
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {intl.formatMessage({ id: "no_connected_device" })} {/* 연결된 포코로가 없어 대화목록을 불러올 수가 없습니다. */}
                    </Typography>
                  }
                />
                :
                <>
                  {conversationStore.conversationList === '' ?
                    <ErrorBox>
                      <SearchErrorIcon />
                      <Typography variant="body1" style={{ whiteSpace: "pre-line" }}>
                        {intl.formatMessage({ id: "unknown_error" })} {/* 알 수 없는 오류로 인해 검색 결과를 불러오지 못했습니다. 다시 시도해주세요. */}
                        <ButtonText onClick={getConversationList}><pre>{intl.formatMessage({ id: "refresh" })}</pre></ButtonText> {/* 새로고침 */}
                      </Typography>
                    </ErrorBox>
                    :
                    <>
                      {conversationStore.conversationList.length === 0 ?
                        <>
                          {conversationStore.searchKeyword === '' ?
                            <CommonEmptyPoKoRo
                              image={PoKoRoSadImage}
                              text={
                                <Typography style={{ whiteSpace: "pre-line" }}>
                                  {intl.formatMessage({ id: "not_enough_conversations" })} {/* 아직 포코로에 쌓인 대화가 부족합니다. 충분한 대화를 한 뒤 다시 접속해주세요 :) */}
                                </Typography>
                              }
                            />
                            :
                            <CommonEmptyPoKoRo
                              image={QuestionPokoro}
                              text={<Typography>{intl.formatMessage({ id: "no_search_results" })}</Typography>} //검색된 결과가 없습니다.
                            />
                          }
                        </>
                        :
                        <ConversationContentComponent />
                      }
                    </>
                  }
                </>
              }
            </>
          }
        </ReportBox>
      </>
    );
  }
  
export default injectIntl(inject('authStore', 'userStore', 'conversationStore')(observer(ConversationList)));