import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { useHistory, useLocation } from "react-router-dom";
import { Box, Stack, Typography } from "@mui/material";
import {
  Container,
  InstructionWrap,
  InstructionStep,
  InstructionText,
  StepNumber,
} from "./Styled/ConnectStyled";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import CommonButton from "../CommonComponent/CommonButton";
import CommonBack from "../CommonComponent/CommonBack";
import PokoroPen from "../../common/Images/PokoroPen.png";
import PokoroInst from "../../common/Images/PokoroInst.png";
import { LocalStorageConnectSkip } from "../../stores/AuthStore";

import { usePenStatus } from "../../nativebridge/PenStatus"; // 커스텀 훅을 import
import CommonLoading from "../CommonComponent/CommonLoading";
import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";

import {
  connectPokoro,
  stopScan,
  disConnectPokoro,
  customSendToken,
  customSendSetting,
} from "../../nativebridge/JsToNative";
import BottomNavigation from "../Home/BottomNavigation";

import {
  NativeToJsBridge,
  NativeToJsEventName,
  NativeToJsEventOnDeviceDisconnected,
  NativeToJsEventOnDeviceConnected,
  NativeToJsEventOnDeviceConnectFail,
} from "../../nativebridge/NativeToJs";

import { COMMON_UTIL } from "../../common/util/common.util";
import { inject, observer } from "mobx-react";

const ConnectionComponent = (props) => {
  const { intl, languageStore } = props;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const showElements = queryParams.get("showElements") === "true";

  const [reqSetLangAfterConnect, setLangAfterConnect] = React.useState(false);

  // 펜 연결 여부 확인
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  // 로딩바
  const [loading, setLoading] = React.useState(false);

  // 완료팝업
  const [goSetting, setGoSetting] = React.useState(false);

  // const [reqSetServer, setServer] = React.useState(false);

  let reqSetServer = false;
  useEffect(() => {
    console.log(globalPenStatus);
    console.log(`useEffect globalPenStatus${reqSetLangAfterConnect}`);
    if (reqSetLangAfterConnect) {
      setLangAfterConnect(false);
      const settingData = {
        messageType: "POKORO2_Settings",
        payload: {
          lang_system: globalPenStatus.deviceInfo.lang_system,
          lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
          lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
          lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
        },
      };
      const lang_speaking = globalPenStatus.deviceInfo.lang_speaking;
      console.log(
        `customSendSetting globalPenStatus.deviceInfo.lang_system=${globalPenStatus.deviceInfo.lang_system},lang_speaking=${lang_speaking}`
      );

      customSendSetting(JSON.stringify(settingData));
    }
  }, [reqSetLangAfterConnect, globalPenStatus]);

  const handleBack = () => {
    history.push("/setting");
  };

  const handlewifiConnect = () => {
    localStorage.setItem(LocalStorageConnectSkip, "true");
    history.push("/wifiConnect");
  };

  const handleClickSkip = () => {
    localStorage.setItem(LocalStorageConnectSkip, "true");
    history.push("/home");
  };

  const onDeviceConnecting = () => {
    console.log("=== onDeviceConnecting");
    setLoading(true);
  };

  const onDeviceConnected = () => {
    // alert("=== onDeviceConnected");
    console.log("=== onDeviceConnected");
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: true, // isConnect 속성만 변경
    }));

    const countryCode = COMMON_UTIL.getCountryCodeFromLanguage(
      languageStore.language
    );

    if (globalPenStatus.deviceInfo.lang_system !== countryCode) {
      setLangAfterConnect(true);
      setGlobalPenStatus((prevStatus) => ({
        ...prevStatus,
        deviceInfo: {
          ...prevStatus.deviceInfo, // spread the previous deviceInfo object
          lang_system: countryCode, // update lang_main
        },
      }));
      // const settingData = {
      //   messageType: "POKORO2_Settings",
      //   payload: {
      //     lang_system: countryCode,
      //     lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
      //     lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
      //     lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
      //   },
      // };
      // customSendSetting(JSON.stringify(settingData));
    }

    setGoSetting(true);
  };

  const onDeviceDisConnected = () => {
    // alert("=== onDeviceDisConnected");
    console.log("=== onDeviceDisConnected");
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
  };

  const onDeviceConnectFail = () => {
    // alert("=== onDeviceConnectFail");
    console.log("=== onDeviceConnectFail");
    setLoading(false);
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
  };

  const onPokoroStatus = (jsonStr) => {
    // alert(`=== onPokoroStatus: ${jsonStr}`);
    setLoading(true);
    console.log(`=== onPokoroStatus: ${jsonStr.jsonStr}`);
    const status = JSON.parse(jsonStr.jsonStr);

    const serverList = JSON.parse(localStorage.getItem("_AGENT_SERVER_LIST_"));
    const Server = serverList.find(
      (server) => server.name === status.payload.serverInfo.name
    );
    const ServerUrl = Server ? Server.serverUrl : null;

    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      batteryPercentage: status.payload.batteryPercentage,
      storagePercentage: status.payload.storagePercentage,
      ConnectedAP1: status.payload.ConnectedAP1,
      ConnectedAP2: status.payload.ConnectedAP2,
      ConnectedAP3: status.payload.ConnectedAP3,
      deviceInfo: status.payload.deviceInfo,
      serverInfo: {
        ...prevStatus.serverInfo,
        name: status.payload.serverInfo.name,
        url: ServerUrl,
      },
      name: status.payload.deviceInfo.name,
    }));
    const settingData = {
      messageType: "POKORO2_Settings",
      payload: {
        server_name: status.payload.serverInfo.name,
        server_url: ServerUrl,
      },
    };
    reqSetServer = true;
    customSendSetting(JSON.stringify(settingData));
    
    // const authData = {
    //   messageType: "POKORO2_Auth",
    //   payload: {
    //     token: localStorage.getItem("_NDP_ACCESS_TOKEN_"), // 동적으로 주어진 토큰 값
    //     refreshToken: localStorage.getItem("_NDP_REFRESH_TOKEN_"), // 동적으로 주어진 리프레시 토큰 값
    //   },
    // };
    // customSendToken(JSON.stringify(authData));
  };

  const onCustomSendResult = (jsonStr) => {
    const result = JSON.parse(jsonStr.jsonStr);
    console.log(`onCustomSendResult=${result}`);
    if(reqSetServer){
      reqSetServer = false;
      const authData = {
        messageType: "POKORO2_Auth",
        payload: {
          token: localStorage.getItem("_NDP_ACCESS_TOKEN_"), // 동적으로 주어진 토큰 값
          refreshToken: localStorage.getItem("_NDP_REFRESH_TOKEN_"), // 동적으로 주어진 리프레시 토큰 값
        },
      };
      customSendToken(JSON.stringify(authData));
    }

  };

  // useEffect(() => {
  //   if (reqSetServer) {
  //     setServer(false);

  //   }
  // }, [reqSetServer]);

  useEffect(() => {
    console.log(
      "====_NDP_ACCESS_TOKEN_: " + localStorage.getItem("_NDP_ACCESS_TOKEN_")
    );
    console.log(
      "====_NDP_REFRESH_TOKEN_: " + localStorage.getItem("_NDP_REFRESH_TOKEN_")
    );
    console.log(
      "====_AGENT_SERVER_LIST_: " + localStorage.getItem("_AGENT_SERVER_LIST_")
    );

    connectPokoro();
    //NativeToJsEventOnDeviceConnecting
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnecting,
      async (event) => {
        onDeviceConnecting();
      }
    );
    //NativeToJsEventOnDeviceConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnected,
      async (event) => {
        onDeviceConnected();
      }
    );

    //NativeToJsEventOnDeviceDisConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        onDeviceDisConnected();
      }
    );

    //NativeToJsEventOnDeviceConnectFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceConnectFail,
      async (event) => {
        onDeviceConnectFail();
      }
    );

    //NativeToJsEventOnPokoroStatus
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onPokoroStatus,
      async (event) => {
        const jsonStr = event;
        onPokoroStatus(jsonStr);
      }
    );

    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        onCustomSendResult(jsonStr);
      }
    );
    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnecting
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceDisConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnectFail
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onPokoroStatus
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
      stopScan();
    };
  }, []);

  return (
    <Container>
      <>
        <Stack
          sx={{ background: "#fffcf0", borderBottom: "1px solid #F0F1F5" }}
        >
          {showElements && <CommonBack onClick={handleBack} />}
          <SubpageTitle>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: "bolder" }}>
              <pre>{intl.formatMessage({ id: "connect_Title" })}</pre> {/* 포코로 연결 */}
            </Typography>
            <Typography variant="body2">
              <pre>{intl.formatMessage({ id: "connect_Text" })}</pre> {/* 연결할 포코로의 전원을 켜신 후 아래 가이드에 표시된 버튼을 눌러주세요 */}
            </Typography>
          </SubpageTitle>
        </Stack>

        <InstructionWrap p={3} justifyContent='center' alignItems='center'>
          <Stack flexDirection="row" gap={2} className='onboardTablet'>
            <img src={PokoroPen} alt="Device" style={{maxHeight:'290px' }} />
            <Stack gap={3} className='right'>
              <Stack gap={1} alignItems='flex-start'>
                <StepNumber>01</StepNumber>
                <Typography variant="body2" whiteSpace='normal'>
                  <pre>{intl.formatMessage({ id: "connect_Note01" })}</pre>
                </Typography>
              </Stack>
              <Stack gap={1} alignItems='flex-start' position='relative'>
                <StepNumber>02</StepNumber>
                <Stack>
                  <Typography variant="body2" whiteSpace='normal'>
                    <pre>{intl.formatMessage({ id: "connect_Note02" })}</pre>
                  </Typography>
                  <img src={PokoroInst} alt="Device"/>
                </Stack>
              </Stack>
              <Stack gap={1} alignItems='flex-start'>
                <StepNumber>03</StepNumber>
                <Typography variant="body2" whiteSpace='normal'>
                  <pre>{intl.formatMessage({ id: "connect_Note03" })}</pre>
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </InstructionWrap>
        {!showElements && (
          <Box p={3}>
            <CommonButton
              text=<pre>
                {intl.formatMessage({ id: "connect_DialogButton" })}
              </pre>
              background={"#fff"}
              border={"#C9C9CA"}
              color={"#909195"}
              onClick={handleClickSkip}
            />
          </Box>
        )}
        <CommonComfirmDialog
          open={goSetting}
          title={intl.formatMessage({ id: "connect_complete_dialog_Title" })}
          contents={intl.formatMessage({ id: "connect_complete_dialog_text" })}
          buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
          onClick={handlewifiConnect}
        />
        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999,
            }}
          >
            <CommonLoading
              text={intl.formatMessage({ id: "pokoro_connecting" })}
            />
          </div>
        )}
      </>
    </Container>
  );
};

export default injectIntl(
  inject("languageStore")(observer(ConnectionComponent))
);
