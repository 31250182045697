import { styled } from '@mui/system';
import { Stack, Typography } from '@mui/material';

export const Container = styled(Stack)({
    width:'100%',
    height:'100vh',
    justifyContent:'space-between',
    boxSizing:'border-box'
  });
  
  export  const InstructionWrap = styled(Stack)({
    height:'100%',
    "& .right img": {
      position:'absolute',
      width:40,
      right:0,
      top: 0
    },
    '& .onboardTablet':{
      '@media screen and (min-width: 768px)':{
        scale: '1.5'
      },
      '@media screen and (min-width: 1200px)':{
        scale: '2'
      }
    }
  })
  export  const InstructionStep = styled(Stack)({
    flexDirection:'row',
    alignItems: 'flex-start',
    gap: '8px'
  });
  
  export  const StepNumber = styled('div')({
    backgroundColor: '#FF4D57',
    color: '#fff',
    borderRadius: '100px',
    padding:'0 8px',
    paddingTop:'3px',
    fontWeight: 'bold',
    fontSize:'0.75rem'
  });
  
  
  export  const InstructionText = styled(Typography)({
    fontSize: '14px',
    color: '#333',
    textAlign: 'left',
  });