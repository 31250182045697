import { Repository } from './Repository';

export default class AuthRepository extends Repository {
    constructor(serverUrl) {
        super();

        this.serverUrl = serverUrl;
        this.authRequestPrefix = '/api/v1/authentications';
    }

    login = (param) => {
        return this.getRequestPromise('post', this.serverUrl + this.authRequestPrefix + `/signin`, param);
    };

    autoLogin = (param) => {
        return this.getRequestPromise('post', this.serverUrl + this.authRequestPrefix + `/auto-signin`, param);
    };

    checkLogin = () => {
        return this.getRequestPromise('get', this.serverUrl + this.authRequestPrefix + `/signcheck`);
    };

    logout = () => {
        return this.getRequestPromise('post', this.serverUrl + this.authRequestPrefix + `/signout`);
    };

    agreeTerms = (userId, param) => {
        return this.getRequestPromise('put', this.serverUrl + this.authRequestPrefix + `/${userId}/terms`, param);
    }

    updateUserInfo = (userId, param) => {
        return this.getRequestPromise('put', this.serverUrl + this.authRequestPrefix + `/${userId}/info`, param);
    }
}
