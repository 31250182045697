import React from "react";
import { injectIntl } from "react-intl";
import { Box } from "@mui/material";
import Chart from "react-apexcharts";
import {styled} from "@mui/material/styles";


const Root = styled(Box)(() => ({
    '& .apexcharts-toolbar':{
        display:'none',
    },
}));

function BarChartComponent({ intl, thisWeekUseTime, lastWeekUseTime }) {    
  const options = {
    xaxis: {
      categories: [intl.formatMessage({ id: "last_week" }), intl.formatMessage({ id: "this_week" })],
      colors: ["#C9C9CA", "#FDD751"],
      labels: {
        style: {
          colors: "#909195",
          fontSize:"0.625rem",
        }
      }
    },
    yaxis: {
      tickAmount: 3,
      min: 0,
      labels: {
        style: {
          colors:"#909195",
          fontSize:"0.625rem",
        },
        formatter: function (val) {
          if (val === null) {
            return intl.formatMessage({ id: "zero_minutes" });
          } else {
            return val.toFixed(0) + intl.formatMessage({ id: "minute" });
          }
        },
      },
      axisBorder: {
        show: true,
      },
    },
    chart: {
      type: 'bar',
    },
    plotOptions: {
      bar: {
        expandOnClick: false,
        borderRadius:4,
        columnWidth: '40px',
        barHeight: '100%',
        distributed: true,
        horizontal: false,
        dataLabels: {
          position: 'top'
        },
      }
    },
    colors: ["#C9C9CA", "#FDD751"],
    states: {
      hover: {
        filter: 'none',
      },
      active: {
        filter: 'none',
      },
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        if (val === null) {
            return intl.formatMessage({ id: "zero_minutes" });
        } else {
            return val.toFixed(0) + intl.formatMessage({ id: "minute" });
        }
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        fontWeight:400,
        colors: ["#909195", "#333"]
      }
    },
    stroke: {
      show: true,
      curve: 'straight',
      lineCap: 'butt',
      colors: undefined,
      width: 1,
      dashArray: 0,
    },
    grid: {
      row: {
        colors: ['#fff', 'transparent'],
        opacity: 0.5,
      },
      yaxis: {
        lines: {
          show: false
        }
      },
    },
    tooltip: {
      enabled: false
    },
    legend: {
      show: false,
    }
  };

  const series = [
      { data: lastWeekUseTime === 0 && thisWeekUseTime === 0 ? [null, null] : [lastWeekUseTime, thisWeekUseTime] },
  ];

  return (
      <Root>
          <Chart options={options} series={series} type="bar" height={300} />
      </Root>
  );
}

export default injectIntl(BarChartComponent);
