import React from "react";
import { injectIntl } from "react-intl";
import { Box, Typography } from "@mui/material";
import SecretPokoro from "../../../common/Images/SecretPokoro.png";
import {
  ContentBox,
  DividerLine,
  TitleBox,
} from "./Styled/PronunciationReviewStyled";
import {inject, observer} from "mobx-react";


function PronunciationReviewComponent(props) {
  const {intl, pronunciationStore} = props;

  return (
    <Box py={2}>
      <TitleBox>
        <img src={SecretPokoro} alt={""} />
          <Typography variant="h6">{intl.formatMessage({ id: "overall_evaluation_score" })}</Typography>
          <ul>
            <li>{intl.formatMessage({ id: "pronunciation_accuracy_card" })} <span>{pronunciationStore.accuracyScoreAverage}</span></li>
            <li>{intl.formatMessage({ id: "intelligibility_card" })}<span>{pronunciationStore.completenessScoreAverage}</span></li>
            <li>{intl.formatMessage({ id: "prosody_fluency_card" })}<span>{pronunciationStore.fluencyScoreAverage}</span></li>
          </ul>
      </TitleBox>
      <ContentBox>
        <DividerLine />
        <Typography variant="subtitle1">{intl.formatMessage({ id: "solution" })}</Typography>
        <ul>
          <li>
            <Typography variant="subtitle1">
            1. <pre>{intl.formatMessage({ id: "pronunciation_accuracy" })}</pre>
            <span>(Pronunciation Accuracy)</span>
            </Typography>
            <Typography variant="body2">
              {pronunciationStore.accuracySolution}
            </Typography>
            {/*<Typography variant="body2">*/}
            {/*  특정 음소에서 어려움이 보입니다. 특히 'th', 'oo', 'u'의 발음이 일관되지 않은 것으로 나타났습니다.*/}
            {/*  <ul>*/}
            {/*    <li className="accuracy">'th'발음은 혀를 앞니 뒤에 살짝 위치시키고 공기를 내뿜으며 소리를 내는 연습이 필요합니다.</li>*/}
            {/*    <li className="accuracy">'oo'발음의 경우 입을 ㅇ모양으로 만들고 점차 입술을 넓혀감으로써 올바른 소리를 낼 수 있도록 주의깊게 연습해야합니다.</li>*/}
            {/*    <li className="accuracy">'u'발음은 입술을 둥글게 하여 'woo' 소리를 내면서 연습하는 것이 좋습니다.</li>*/}
            {/*  </ul>*/}
            {/*</Typography>*/}
          </li>
          <li>
            <Typography variant="subtitle1">
            2. <pre>{intl.formatMessage({ id: "intelligibility" })}</pre>
            <span>(Fluency Score)</span>
            </Typography>
            <Typography variant="body2">
              {pronunciationStore.fluencySolution}
            </Typography>
          </li>
          <li>
            <Typography variant="subtitle1">
            3. <pre>{intl.formatMessage({ id: "stress_intonation_rhythm_fluency" })}</pre>
            <span>(Stress & Intonation / Rhythm & Fluency)</span>
            </Typography>
            <Typography variant="body2">
              {pronunciationStore.speechAttributesSolution}
            </Typography>
          </li>
        </ul>
        <DividerLine />
        <Typography variant="subtitle1">
        {intl.formatMessage({ id: "pronunciation_improvement_goal" })}
        </Typography>
        <ul>
          <li>
            <Typography variant="body1">
              {pronunciationStore.improvementGoalsSolution}
            </Typography>
          </li>
          {/*<li>*/}
          {/*  <Typography variant="subtitle2">*/}
          {/*  개선된 점 :*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body2">*/}
          {/*  첫번쨰 날과 비교해 마지막 날에 많은 발전이 있었습니다. 특히 'I'와 'hope'의 발음이 좋아졌습니다.*/}
          {/*  </Typography>*/}
          {/*</li>*/}
          {/*<li>*/}
          {/*  <Typography variant="subtitle2">*/}
          {/*  개선이 필요한 부분 :*/}
          {/*  </Typography>*/}
          {/*  <Typography variant="body2">*/}
          {/*  첫번쨰 날과 비교해 마지막 날에 많은 발전이 있었습니다. 특히 'I'와 'hope'의 발음이 좋아졌습니다.*/}
          {/*  </Typography>*/}
          {/*</li>*/}
        </ul>
      </ContentBox>
    </Box>
  );
}

export default injectIntl(inject('pronunciationStore')(observer(PronunciationReviewComponent)));
