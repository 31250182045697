import React, {useEffect} from "react";
import { injectIntl } from "react-intl";
import {Chip, Typography} from "@mui/material";

import { ReactComponent as ArrowLeftIcon } from "../../../common/Images/ArrowLeftIcon.svg";
import { ListCaption, ListTitle } from "../../../common/Styled/CommonStyled";
import {
  AnswerBox,
  BoxTitle,
  ButtonBack,
  CardBoxViewer,
  ChatBox,
  ChipBox,
  ListBox,
  QuestionBox,
  Root,
  WrapBox,
} from "./Styled/ConversationContentDetailComponentStyled";
import { WordListBox, WordList } from "../Pronunciation/Styled/PronunciationListDetailStyled";
import {inject, observer} from "mobx-react";
import CommonLoading from "../../CommonComponent/CommonLoading";
import {DATE_UTIL} from "../../../common/util/date.util";

function ConversationContentDetailComponent(props) {
  const {intl, authStore, userStore, conversationStore} = props;

  useEffect(() => {
    const scrollToElement = () => {
      if (conversationStore.topScrollMessage !== '') {
        const messageElements = document.getElementsByTagName('span');
        for (let i = 0; i < messageElements.length; i++) {
          if (messageElements[i].innerText === conversationStore.topScrollMessage) {
            messageElements[i].scrollIntoView({ behavior: 'smooth' });
            conversationStore.setTopScrollMessage('');
            break;
          }
        }
      }
    }
    setTimeout(scrollToElement, 300);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    let intervalId;

    if (conversationStore.conversation.startDatetime === null) {
      intervalId = setInterval(() => {
        conversationStore.getLatestRealTimeConversation(
            authStore.loginUser.id,
            userStore.selectedDevice.deviceId,
            conversationStore.conversation.dialogId
        );
      }, 2000);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationStore.conversation]);

  let colorList = ['#305580', '#B79470', '#FCB100', '#9277BF', '#5CBA47', '#DE6F9E', '#DE765F', '#90CBFB', '#FEA443'];

  const getColorByWord = (word) => {
    const randomIndex = Math.floor(Math.random() * colorList.length);
    const randomColor = colorList[randomIndex];

    colorList = colorList.filter((_, index) => index !== randomIndex);

    return randomColor;
  }

  const getDate = (dialogStartDatetime) => {
    return DATE_UTIL.convertUTCToTimeZone(dialogStartDatetime, 'YYYY.MM.DD A hh:mm');
  }

  const getDuration = (duration) => {
    if (duration < 60) {
      if (duration < 1) {
        duration = 1;
      }
      return duration + ` ${intl.formatMessage({ id: "seconds" })}`;
    }

    let hours = Math.floor(duration / 3600);
    let minutes = Math.floor((duration % 3600) / 60);

    let timeString = '';

    if (hours > 0) {
      timeString += hours + ` ${intl.formatMessage({ id: "hour" })} `;
    }

    if (minutes > 0) {
      timeString += minutes + ` ${intl.formatMessage({ id: "minute" })} `;
    }

    return timeString.trim();
  }

  const handleClickMoveList = () => {
    conversationStore.setType('list');
  }


  return (
    <Root>
      <ButtonBack disableRipple onClick={handleClickMoveList}>
        <ArrowLeftIcon />
        <Typography>{intl.formatMessage({ id: "toList" })}</Typography> {/* 목록으로 */}
      </ButtonBack>
      <React.Fragment>
        {conversationStore.isConversationLoading ?
            <div style={{marginTop: '30%'}}>
              <CommonLoading text={`${intl.formatMessage({ id: "loading_message" })}.`} />
            </div>
            :
            <React.Fragment>
              {conversationStore.conversation !== '' &&
                  <WrapBox>
                    <CardBoxViewer>
                      <ChipBox>
                        {conversationStore.conversation.topicWordList?.map((word, index) => {
                          const color = getColorByWord(word);
                          return (
                              <Chip key={index} label={word} style={{ backgroundColor: color} } />
                          )
                        })}
                      </ChipBox>
                      <BoxTitle>
                        <ListTitle variant="subtitle2">
                          {conversationStore.conversation.firstMessage}
                        </ListTitle>
                        {conversationStore.conversation.startDatetime !== null ?
                            <ListCaption>
                              {getDate(conversationStore.conversation.startDatetime)}  /  {getDuration(conversationStore.conversation.duration)}
                            </ListCaption>
                            :
                            <ListCaption>{intl.formatMessage({ id: "in_conversation" })}</ListCaption>
                        }
                      </BoxTitle>
                      <ListBox>
                        <ul style={{listStyle:'initial'}}>
                          {conversationStore.conversation.summaryList?.map((summary, index) => (
                              <li key={index}>{summary}</li>
                          ))}
                        </ul>
                      </ListBox>
                    </CardBoxViewer>

                    <ChatBox>
                      {conversationStore.conversation.dialogList.map((dialog, index) => {
                        if (dialog.role === 'user') {
                          return (
                              <QuestionBox key={index}>
                                <span>{dialog.message}
                                  {dialog.mode === 'Pronunciation' &&
                                      <WordListBox mt={1}>
                                        {dialog.pronunciation?.wordList.map((word, index) => (
                                            <WordList key={index} mr={2.5}>
                                              <Typography variant="body1" color={word.score <= 45 ? "#fe536b": null}>{word.word}</Typography>
                                              <Typography variant="caption">
                                                {word.phonemeList.map((phoneme, index) => (
                                                    <span key={index}>
                                                <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.phoneme}</span>
                                                      {index !== word.phonemeList.length - 1 && "・"}
                                              </span>
                                                ))}
                                              </Typography>
                                              <Typography variant="caption" style={{ display: "block" }}>
                                                {word.phonemeList.map((phoneme, index) => (
                                                    <span key={index}>
                                                  <span style={{ color: phoneme.score <= 45 ? "#fe536b" : null }}>{phoneme.score}</span>
                                                      {index !== word.phonemeList.length - 1 && "・"}
                                                </span>
                                                ))}
                                              </Typography>
                                            </WordList>
                                        ))}
                                      </WordListBox>
                                  }
                                </span>
                              </QuestionBox>
                          )
                        } else {
                          return (
                              <AnswerBox key={index}>
                                <span>{dialog.message}</span>
                              </AnswerBox>
                          )
                        }
                      })}
                    </ChatBox>                      
                  </WrapBox>
              }
            </React.Fragment>
        }
      </React.Fragment>
    </Root>
  );
}

export default injectIntl(inject('authStore', 'userStore', 'conversationStore')(observer(ConversationContentDetailComponent)));