import {makeAutoObservable} from "mobx";
import {DATE_UTIL} from "../common/util/date.util";
import dayjs from "dayjs";

const logPrefix  = "[HomeStore]"
export default class HomeStore {
    constructor(props) {
        this.userRepository = props.userRepository;

        this.init();

        makeAutoObservable(this);
    }

    init = () => {
        this.isCalendarLoading = false;
        this.isHomeDataLoading = false;

        const now = new Date();
        this.selectedCalendarDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
        this.selectedCalendarYear = this.selectedCalendarDate.getFullYear();
        this.selectedCalendarMonth = this.selectedCalendarDate.getMonth() + 1;

        this.calendarDateList = [];
        this.homeData = '';
    }

    get getIsLoading() {
        return this.isCalendarLoading || this.isHomeDataLoading;
    };

    setSelectedCalendarDate = (date) => {
        this.selectedCalendarDate = date;
        this.selectedCalendarYear = date.getFullYear();
        this.selectedCalendarMonth = date.getMonth() + 1;
    }

    setSelectedCalendarYear = (year) => {
        this.selectedCalendarYear = year;
    }

    setSelectedCalendarMonth = (month) => {
        this.selectedCalendarMonth = month;
    }

    * getCalendar(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start getUserCalendar.');
            this.isCalendarLoading = true;

            const response = yield this.userRepository.getCalendar(userId, deviceId, this.selectedCalendarYear, this.selectedCalendarMonth, DATE_UTIL.getTimeDiffWithUTC());

            this.calendarDateList = response.data;

            this.isCalendarLoading = false;
            console.log(logPrefix, 'Finished getUserCalendar.');

            callback && callback(userId, deviceId);
        } catch(e) {
            this.calendarDateList = [];
            console.log(logPrefix, 'Failed getUserCalendar. error: ' + e);
        } finally {
            this.isCalendarLoading = false;
        }
    }

    * getHomeData(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start getHomeData.');
            this.isHomeDataLoading = true;

            const startDatetime = dayjs(this.selectedCalendarDate).startOf('day');
            const endDatetime = startDatetime.endOf('day');

            const response = yield this.userRepository.getHomeData(userId, deviceId, startDatetime.toISOString(), endDatetime.toISOString(), DATE_UTIL.getTimeDiffWithUTC());

            this.homeData = response.data;

            this.isHomeDataLoading = false;
            console.log(logPrefix, 'Finished getHomeData.');

            callback && callback();
        } catch(e) {
            this.homeData = '';
            console.log(logPrefix, 'Failed getHomeData. error: ' + e);
        } finally {
            this.isHomeDataLoading = false;
        }
    }
}
