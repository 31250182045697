import { useLocation } from "react-router";
import { injectIntl } from "react-intl";
import React, { useEffect,useState} from "react";
import { Root } from "../../../common/Styled/CommonStyled";
import { TitleStyle } from "../../../common/Styled/CommonStyled";
import { inject, observer } from "mobx-react";
import PronunciationData from "./PronunciationData"
import { Icon } from '@iconify/react';
import BottomNavigation from "../BottomNavigation";
import { isPronunciation } from "../../../nativebridge/JsToNative";


function Pronunciation(props) {
  const [tabValue, setTabValue] = useState("pronunciation");
  const {intl, loadingStore, pronunciationStore} = props;
  const { location } = useLocation();
  useEffect(() => {
    isPronunciation(true);
    return () => {
      isPronunciation(false);
    };
  }, []);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location]);
    useEffect(() => {
        loadingStore.setIsLoading(pronunciationStore.getIsLoading);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pronunciationStore.getIsLoading]);

  return (
    <Root>
      {tabValue === "pronunciation" && (
        <>
          <TitleStyle>
            <Icon icon="emojione:loudspeaker" />{intl.formatMessage({ id: "pronunciation_title" })}
          </TitleStyle>
          <PronunciationData/>
        </>
      )}
      <BottomNavigation value={tabValue} handleChangeTab={(event, newValue) => setTabValue(newValue)} />
    </Root>
  );
}

export default injectIntl(inject('loadingStore', 'pronunciationStore')(observer(Pronunciation)));