import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";


export const BoxIn = styled(Box)(() => ({
  width:'100%',
  height:'100vh',
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: "24px 0 28px",
  "& p": {
    fontSize: "0.875rem",
    color: "#333",
    lineHeight: "19.6px",
    fontWeight: 400,
    textAlign: "center",
    marginTop: 16,
  },
}));

export const TitleText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.375rem",
    fontWeight: 700,
    color: "#333",
    textAlign:'center',
    wordWrap: 'break-word',
    marginTop:24,
    marginBottom:8,
  },
}));

export const ContentText = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    color: "#212121",
    textAlign:'center',
    wordWrap: 'break-word',
  },
}));

