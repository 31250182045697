import { Box, Typography, Stack, Button } from "@mui/material";
import { styled } from "@mui/material/styles";


export const MoreButton = styled(Button)(() => ({
  width: "100%",
  display: "flex",
  justifyContent: "center",
  fontSize: "0.875rem",
  fontWeight: 400,
  color: "#909195",
  "&:hover": {
    backgroundColor: "transparent",
  },
}));



export const Container = styled(Box)(() => ({
  paddingTop: 53,
  paddingBottom: 75,
}));

export const EmptyBox = styled(Box)(() => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  gap: 32,
  "& .MuiTypography-root": {
    textAlign: "center",
  },
  "& .MuiTypography-body1": {
    fontSize: "0.875rem",
  },
}));


/* -------------추가---------------- */

export const TodaySituation = styled(Stack)({
  padding: '24px 16px',
  backgroundColor: '#fff',
  borderRadius: '8px',
  boxShadow: '0 -1px 3px rgba(0, 0, 0, 0.05)',
  gap: '0.5rem'
});

export const DateBox = styled(Typography)({
  fontSize: '1.125rem',
  fontWeight: '500',
});

export const BoxWrap = styled(Stack)({
  gap:'8px',
  padding: '16px 0',
  borderBottom: '1px dashed #F0F1F5',
  '&:last-child':{
    border:0
  }
})
export const StatusBox = styled(Stack)({
  flexDirection:'row',
  marginBottom: '24px',
  '& > div': {
    flex: '1 0 0',
    textAlign: 'center',
    gap: '8px',
    '& > div.img': {
      width:'40px',
      height:'40px',
      borderRadius:'100px',
      fontSize:'1.5rem',
      '& svg':{
        padding: '8px'
      }
    }
  },
  '& > .line': {
    width:'1px',
    height:'96px',
    background:'#F0F1F5'
  }
});

export const StatusText = styled(Typography)({
  fontSize: '0.875rem',
  color: '#888',
});

export const StatusValue = styled(Typography)({
  fontWeight: 'bold',
});


export const KeywordCloud = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '8px',
  padding: '16px 0',
});


export const Keyword = styled(Typography)({
  fontSize: '14px',
  color: '#666',
});

