import { Repository } from './Repository';

export default class ConversationRepository extends Repository {
    constructor(serverUrl) {
        super();

        this.serverUrl = serverUrl;
        this.conversationRequestPrefix = '/api/v1/dialog';
    }

    getConversationList = (userId, deviceId, searchKeyword, rowsPerPage, page, sortDirection) => {
        return this.getRequestPromise('get', this.serverUrl + this.conversationRequestPrefix + `?userId=${userId}&deviceId=${deviceId}&searchKeyword=${searchKeyword}&rowsPerPage=${rowsPerPage}&page=${page}&sortDirection=${sortDirection}`);
    };

    getConversation = (userId, deviceId, dialogId) => {
        return this.getRequestPromise('get', this.serverUrl + this.conversationRequestPrefix + `/${dialogId}?userId=${userId}&deviceId=${deviceId}`);
    };

    getRealTimeConversation = (userId, deviceId, dialogId, lastId, againRequestDialogIdList) => {
        return this.getRequestPromise('get', this.serverUrl + this.conversationRequestPrefix + `/${dialogId}/real-time?userId=${userId}&deviceId=${deviceId}&lastId=${lastId}&againRequestDialogIdList=${againRequestDialogIdList}`);
    };
}
