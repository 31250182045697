import {makeAutoObservable} from "mobx";


export default class ModalStore {
    constructor() {
        this.newPokoroModalOpen = false;
        this.changePokoroModalOpen = false;

        makeAutoObservable(this);
    }

    setNewPokoroModalOpen = (open) => {
        this.newPokoroModalOpen = open;
    }

    setChangePokoroModalOpen = (open) => {
        this.changePokoroModalOpen = open;
    }

}
