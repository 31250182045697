import React from 'react';
import ApexCharts from 'react-apexcharts';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { injectIntl } from 'react-intl';
import { Box, Stack, Typography } from '@mui/material';
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);

const PronunciationGraph = ({ languageStore, intl, view, pronunciationData = [], type }) => {
  const isEmptyData = pronunciationData.length === 0;

  const generateWeeklyLabels = (data) => {
    return data.map(item => {
      const day = item.date.split('-')[2];
      return `${parseInt(day)}${intl.formatMessage({ id: 'day_index' })}`;
    });
  };

  const generateMonthlyLabels = (data) => {
    return data.map(item => {
      const month = item.date.split('-')[1];

      if (languageStore.language !== undefined && languageStore.language === 'en') {
        const monthAbbreviations = [
          'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
          'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
        ];

        return monthAbbreviations[month - 1];
      } else {
        return `${parseInt(month)}${intl.formatMessage({ id: 'month_index' })}`;
      }
    });
  };

  // 임시 데이터 생성
  const placeholderData = [
    { date: '2024-01-01', score: 83 },
    { date: '2024-02-02', score: 50 },
    { date: '2024-03-03', score: 72 },
    { date: '2024-04-04', score: 76 },
    { date: '2024-05-05', score: 90 },
    { date: '2024-06-06', score: 98 },
    { date: '2024-07-07', score: 67 },
  ];

  const dataToUse = isEmptyData ? placeholderData : pronunciationData;

  const data = {
    daily: {
      series: [{ name: 'Comp', data: dataToUse.map(item => item.score) }],
      xaxis: { categories: generateWeeklyLabels(dataToUse) },
    },
    weekly: {
      series: [{ name: 'Comp', data: dataToUse.map(item => item.score) }],
      xaxis: { categories: generateWeeklyLabels(dataToUse) },
    },
    monthly: {
      series: [{ name: 'Comp', data: dataToUse.map(item => item.score) }],
      xaxis: { categories: generateMonthlyLabels(dataToUse) },
    },
  };

  const options = {
    chart: { 
      type: 'bar',
      animations: {
        enabled: false
      },
    },
    xaxis: data[view].xaxis,
    plotOptions: {
      bar: {
        expandOnClick: false,
        dataLabels: { position: 'top' },
        borderRadius: { topLeft: 8, topRight: 8 },
        distributed: true,
      },
    },
    states: {
      hover: {
        filter: 'none',
      },
      active: {
        filter: 'none',
      },
    },
    tooltip: {
      enabled: false
    },
    dataLabels: {
      enabled: true,
      style: { colors: ['#333'], fontWeight: 'normal', fontSize: '10px' },
      offsetY: -15,
    },
    yaxis: { min: 0, max: 100, tickAmount: 4 },
    legend: { show: false },
  };

  const series = data[view].series.map(serie => ({
    ...serie,
    data: serie.data.map((value, i) => ({
      x: data[view].xaxis.categories[i],
      y: value,
      fillColor: '#C9C9CA',
    })),
  }));

  return (
    <Box position='relative'>
      {isEmptyData && (
        <div
          style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          textAlign: 'center'
        }}>
          {intl.formatMessage({ id: 'no_data_text' })}
        </div>
      )}
      <Stack gap={1} id="chart">
        {type === 'daily' &&
          <div style={{ fontSize: '1rem', fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'pronunciation_chart_title' })}
          </div>
        }
        <ApexCharts 
          options={options} 
          series={series} 
          type="bar" 
          height={250}
          style={{ border: isEmptyData ?'1px solid #aaa':'', borderRadius:isEmptyData ?'10px':'', opacity: isEmptyData ? 0.1 : 1 }}
        />
      </Stack>
    </Box>
  );
};

export default injectIntl(PronunciationGraph);
