import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const BoxIn = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  boxSizing: "border-box",
  padding: "64px 0 28px",
  "& p": {
    fontSize: "0.875rem",
    color: "#333",
    lineHeight: "19.6px",
    fontWeight: 400,
    textAlign: "center",
    marginTop: 16,
  },
}));
