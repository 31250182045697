import { Link } from 'react-router-dom';
import { Box, Typography, Tabs } from "@mui/material";
import { styled } from "@mui/material/styles";


export const Root = styled(Box)(() => ({
  display:'block',
  width:'100%',
  '& .wordcloud':{
    maxWidth:'500px',
    width:'100%',
    margin:'0 auto',
    '& svg':{
      width:'100%',
      height: '200px',
    }
  }
}));

export const LinkButton = styled(Link)(({ disabled }) => ({
  textDecoration: 'none',
  display:'block',
  padding: "4px 0",
  boxSizing: "border-box",
  marginBottom: 8,
  opacity: disabled ? 0.2 : 1,
  lineHeight: "19.6px",
  fontWeight: 400,
  color: "#333",
  "& .lang":{
    fontSize:'0.875rem',
    color:'#909195'
  }
}));


export const CardBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  border: "1px solid #F0F1F5",
  borderRadius: 20,
  padding: "32px  16px",
  margin: "16px 0",
}));

export const SolidBox = styled(Box)(() => ({
  backgroundColor: "#fff",
  borderRadius: 16,
  padding: "24px 16px",
  margin: "8px 0",
}));

export const TitleStyle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1.25rem",
    fontWeight: 600,
    color: "#333",
    padding:"24px 16px",
    display:'flex',
    flexDirection:'row',
    gap:'8px',
    alignItems:'center'
  },
}));
export  const SubpageTitle = styled(Box)({
  width: '100%',
  padding: 16,
  paddingBottom: 24,
  boxSizing:'border-box',
});
export const CustomTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    backgroundColor: '#333!important', 
  },
  '& .MuiTab-root': {
    color: '#C9C9CA!important', 
  },
  '& .Mui-selected': {
    color: '#333!important',
    textTransform:'none!important'
  },
});

export const ListTitle = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "1rem",
    fontWeight: 700,
    color: "#333",
    "& span": {
      fontSize: "0.875rem",
      fontWeight: 400,
    },
  },
}));

export const ListCaption = styled(Typography)(() => ({
  "&.MuiTypography-root": {
    fontSize: "0.75rem",
    fontWeight: 400,
    color: "#909195",
  },
}));

export const Loader = styled('span')(() => ({
  position:'relative',
  width : 48,
  height: 48,
  borderRadius: '50%',
  display: 'inline-block',
  border: '5px solid #F0F1F5',
  boxSizing:'border-box',
  '& .spinner':{
    position: 'absolute',
    top: -5,
    left: -5,
    width: 48,
    height: 48,
  },
  '& .path': {
    position:'absolute',
    top:0,
    left:0,
    stroke: '#FDD751',
    strokeLinecap: 'round',
    animation: `dash 1s ease-in-out infinite`,
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%' :{transform: 'rotate(360deg)'},
  },
  '@keyframes dash':{
    '0%': {
      strokeDasharray: '1, 150',
      strokeDashoffset: '0',
    },
    '50%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -35,
    },
    '100%': {
      strokeDasharray: '90, 150',
      strokeDashoffset: -124,
    }
  }
}));


/* calendar */
export const CalendarBox = styled(Box)(({ theme }) => ({
  background: '#FFFCF0',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',

  '&.home':{
    padding: '32px 16px',
    '& .pokoro-icon': {
      position: 'absolute',
      top: 0,
      left: '50%',
      transform:'translateX(-60%)',
      width: '30px',
      height: '30px'
    },
  },

  '&.data':{
    position:'absolute',
    zIndex:10,
    top:60,
    left:'50%',
    transform:'translateX(-50%)'
  },

  '.react-calendar': {
    maxWidth: '100%',
    minHeight: '320px',
    background: 'white',
    border: '1px solid #FFEDAF',
    borderRadius: '0',
    padding: '0.5rem 0'
  },
  '.react-calendar__navigation': {
    marginBottom:'0.5rem',
  },
  '.react-calendar__tile--active:enabled:hover abbr, .react-calendar__tile--active abbr, .react-calendar__tile--hasActive abbr':{
    background:'#FFDF85!important',
    borderRadius:100,
    color:'#333'
  },
  '.react-calendar__tile--active:enabled:hover, .react-calendar__tile--active, .react-calendar__tile--hasActive':{
    background:'transparent!important',
  },
  '.react-calendar__decade-view__years__year, .react-calendar__century-view__decades__decade':{
    padding:'16px 0!important'
  },
  '.react-calendar__month-view__weekdays, .react-calendar__month-view__days':{
    padding:'0 16px'
  },
  'abbr':{
      width: '36px',
      height: '36px',
      margin: '2px',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textDecoration: 'none'
  },
  '.react-calendar__tile, .react-calendar__month-view__weekdays__weekday': {
    position:'relative',
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  '.react-calendar__tile--now': {
    background: 'none!important',
    color: '#333',
    '& abbr':{
    border: '1px solid #FF993C',
    background:'#FF993C!important',
    color:'#fff',
    borderRadius:'100px',
    }
  },
  '.react-calendar__tile--now:hover, .react-calendar__tile--now:focus': {
    background: 'none',
  },

  '.react-calendar__month-view__days__day--weekend': {
    color: '#FF5B5B',
  },
  '.react-calendar__tile':{
    justifyContent: 'center',
    '&.act':{
      alignItems:'flex-end',
      '& abbr':{
        height:'15px',
        background: 'orange',
        borderRadius: '100px',
        color:'#fff',
        zIndex:1
      }
    },
  },

}));
