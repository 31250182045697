import React from 'react';
import { injectIntl } from "react-intl";
import {Stack, Typography} from "@mui/material";
import {Header, ReportBox} from '../Styled/SubPageStyled'
import { ScoreSection, FeedbackSection, Feedback, TipsSection, CardBoxStyle } from './Styled/PronunciationGraphStyled';
import BarChartComponent from "../../CommonComponent/Chart/BarChart";
import PronuncuationCircularProgressbar from "../../CommonComponent/Chart/PronunciationCirularProgressbar"
import PronunciationReviewComponent from './PronunciationReviewComponent';
import { Icon } from '@iconify/react';
import {inject, observer} from "mobx-react";
import {DATE_UTIL} from "../../../common/util/date.util";
import dayjs from "dayjs";

function ScoreSummary (props) {
  const {intl, homeStore, languageStore, pronunciationStore, view } = props;

  const getPronunciationDatetime = () => {
      const nowDate = dayjs().format('YYYY-MM-DD');
      const calendarDate = pronunciationStore.calendarDate.format('YYYY-MM-DD');

      if (nowDate === calendarDate) {
          return dayjs().format(DATE_UTIL.FORMAT.AMPM_FORMAT);
      } else {
          return pronunciationStore.calendarDate.endOf('day').format(DATE_UTIL.FORMAT.AMPM_FORMAT);
      }
  }

  return (
    <ReportBox>
      <Header>
        <h3><Icon icon={'emojione:bow-and-arrow'} />{intl.formatMessage({ id: "accuracy_score" })}</h3>
      </Header>

      <ScoreSection>
        <Stack gap='3rem'>
        {view === "daily" && (
          <Stack alignItems='center' gap='0.5rem' sx={{width:'100%'}}>
            <div style={{ position: 'relative', width: '60vw' }}>
              <PronuncuationCircularProgressbar value={pronunciationStore.todayScore} text={``} pathColor="#fdd751"/>
              <div style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                color: '#333'
              }}>
                <div style={{ fontSize: '2rem', fontWeight: 'bold' }}>{pronunciationStore.todayScore}{intl.formatMessage({ id: "en_score" })}</div>
              </div>
            </div>
            <p style={{textAlign:'center'}}>
                {getPronunciationDatetime()}&nbsp;
                {intl.formatMessage({ id: "based_on" })}
            </p>
          </Stack>
          )}
          <BarChartComponent languageStore={languageStore} view={view} pronunciationData={pronunciationStore.graphList} type={view} />
        </Stack>
      </ScoreSection>
      {pronunciationStore.pronunciationList.length > 0 && (
        <>
          {/* 피드백 섹션 */}
          <FeedbackSection>
          <Feedback>
            <h4><Icon icon={'jam:alert-f'} />{intl.formatMessage({ id: "keep_trying" })}</h4> {/* 노력해봐요 */}
            <CardBoxStyle>
              {pronunciationStore.badPhonemeList.map((phoneme, index) => (
                  <ul key={index}>
                    <li className='score'>
                      <p>{phoneme.phoneme}</p>
                      <p>{phoneme.score}</p>
                    </li>
                    <li className='words'>{phoneme.wordList.join(' / ')}</li>
                  </ul>
              ))}
            </CardBoxStyle>
          </Feedback>
          <Feedback>
            <h4><Icon icon={'ic:sharp-flag-circle'} />{intl.formatMessage({ id: "good_pronunciation" })}</h4> {/* 좋은 발음이예요 */}
            <CardBoxStyle>
              {pronunciationStore.goodPhonemeList.map((phoneme, index) => (
                  <ul key={index}>
                    <li className='score well'>
                      <p>{phoneme.phoneme}</p>
                      <p>{phoneme.score}</p>
                    </li>
                    <li className='words'>{phoneme.wordList.join(' / ')}</li>
                  </ul>
              ))}
            </CardBoxStyle>
          </Feedback>
        </FeedbackSection>

        {/* 팁 섹션 */}
        {pronunciationStore.selectedTab === 'daily' &&
          <TipsSection>
            <PronunciationReviewComponent/>
          </TipsSection>
        }
      </>
      )}


    </ReportBox>
  );
};

export default injectIntl(inject('pronunciationStore', 'homeStore', 'languageStore')(observer(ScoreSummary)));
