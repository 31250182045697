import React, { useState, useEffect } from "react";
import { injectIntl } from "react-intl";
import { Box, Typography, Stack, InputAdornment, IconButton, FormLabel } from "@mui/material";
import { Icon } from "@iconify/react";
import CommonBack from "../CommonComponent/CommonBack";
import CommonButton from "../CommonComponent/CommonButton";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import {
  InfoBox,
  SignInContentsBox,
  InputField,
} from "../SignIn/Styled/SignInStyled";
import { inject, observer } from "mobx-react";
import { useHistory } from "react-router-dom";

import CommonComfirmDialog from "../CommonComponent/CommonComfirmDialog";
import {
  startWifiScan,
  wifiInputPassword,
} from "../../nativebridge/JsToNative";
import {
  NativeToJsBridge,
  NativeToJsEventName,
  getPlatform,
} from "../../nativebridge/NativeToJs";
import { usePenStatus } from "../../nativebridge/PenStatus"; // 커스텀 훅을 import
import CommonLoading from "../CommonComponent/CommonLoading";



function NewWifiConnect(props) {
  const { intl } = props;
  const history = useHistory();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [wifiPassword, setWifiPassword] = useState("");
  const [wifiName, setWifiName] = useState("");

  const [disconnect, setDisconnect] = React.useState(false);
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [loading, setLoading] = React.useState(false);
  const [wifiConnected, setWifiConnected] = React.useState(false);

  const [wifiConnectFail, setWifiConnectFail] = React.useState(false);
  const [wifiFailReasonTitle, setWifiFailReasonTitle] = React.useState("");
  const [wifiFailReasonMsg, setWifiFailReasonMsg] = React.useState("");


  const handleBack = () => {
    history.goBack();
  };
  const handleGoSetting = () => {
    history.push("/setting"); // setting 페이지로 돌아가기
  };


  const handleWifiConnect = () => {
    setLoading(true);
    wifiInputPassword(wifiName, wifiPassword);// 와이파이 연결시도 

  };

  const handleWifiFail = () => {
    setWifiConnectFail(false);
  };


  const handleTogglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  const isButtonDisabled = () => {
    // Logic to determine if button should be disabled
    return (!wifiPassword && !wifiName); // Example condition
  };

  const onDeviceDisConnected = () => {
    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus, // 현재 상태를 복사
      isConnect: false, // isConnect 속성만 변경
    }));
    setDisconnect(true);
    handleGoSetting();
  };

  const onDeviceWifiConnected = () => {
    setWifiConnected(true);
    setLoading(false);
    console.log("WifiConnect ===onDeviceWifiConnected");
  };
  const onDeviceWifiConnectFail = (msg) => {
    setLoading(false);
    console.log(`WifiConnect ===onDeviceWifiConnectFail : ${msg}`);
    if (msg.msg === '0') {
      setWifiFailReasonTitle(
        intl.formatMessage({ id: "wifi_fail_title" })
      )
      setWifiFailReasonMsg(
        intl.formatMessage({ id: "wifi_fail_msg" })
      )
    } else {
      setWifiFailReasonTitle(
        intl.formatMessage({ id: "wifi_fail_title" })
      )
      setWifiFailReasonMsg(
        intl.formatMessage({ id: "wifi_fail_msg2" })
      )
    }

    console.log(`WifiConnect ===onDeviceWifiConnectFail Title: ${wifiFailReasonTitle} , msg:${wifiFailReasonMsg}`);
    setWifiConnectFail(true);

  };

  useEffect(() => {

    //NativeToJsEventOnDeviceDisConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceDisConnected,
      async (event) => {
        onDeviceDisConnected();
      }
    );


    //NativeToJsEventOnDeviceWifiConnected
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnected,
      async (event) => {
        onDeviceWifiConnected();
      }
    );
    //NativeToJsEventOnDeviceWifiConnectFail
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onDeviceWifiConnectFail,
      async (event) => {
        const jsonStr = event;
        onDeviceWifiConnectFail(jsonStr);
      }
    );
    // 컴포넌트 언마운트 시 이벤트 제거
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnected
      );
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onDeviceWifiConnectFail
      );
    };
  }, []);
  return (
    <Stack width='100%'>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.8)",
            zIndex: 9999, // 충분히 큰 z-index로 다른 요소들 위에 올리기
          }}
        >
          <CommonLoading text={intl.formatMessage({ id: "connecting_to_wifi" })} />
        </div>
      )}
      <CommonComfirmDialog
        open={disconnect}
        title={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        contents={intl.formatMessage({ id: "disconnect_dialog_Title" })}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleGoSetting}
      />
      <CommonComfirmDialog
        open={wifiConnected}
        title={intl.formatMessage({ id: "wifi_connected" })}
        contents={intl.formatMessage({ id: "wifi_connected" })}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleGoSetting}
      />
      <CommonComfirmDialog
        open={wifiConnectFail}
        title={wifiFailReasonTitle}
        contents={wifiFailReasonMsg}
        buttonText={intl.formatMessage({ id: "dialog_confirmButton" })}
        onClick={handleWifiFail}
      />
      <Stack sx={{ background: '#FBFCFE', borderBottom: '1px solid #F0F1F5' }}>
        <CommonBack onClick={handleBack} />
        <SubpageTitle sx={{ background: 'unset' }}>
          <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder' }}>
            {intl.formatMessage({ id: "add_new_wifi" })} {/* 새로운 Wi-Fi 추가 */}
          </Typography>
          <Typography variant="body2">
            {intl.formatMessage({ id: "new_wifi_txt" })} {/* 포코로에 연결할 Wi-Fi 정보를 직접 입력해주세요. */}
          </Typography>
        </SubpageTitle>
      </Stack>
      <SignInContentsBox className="modify">
        <InfoBox mt={2}>
          <Box width='100%' maxWidth='500px'>
            <FormLabel>
              {intl.formatMessage({ id: "new_wifi_name" })} {/* 새로운 Wi-Fi 이름 */}
            </FormLabel>
            <InputField
              sx={{ marginBottom: '24px' }}
              placeholder={intl.formatMessage({ id: "new_wifi_name_txt" })} /* Wi-Fi 이름을 입력해주세요. */
              autoComplete="off"
              value={wifiName}
              onChange={(e) => setWifiName(e.target.value)}
              InputLabelProps={{
                shrink: true,
                sx: {
                  "& .MuiInputLabel-asterisk": { color: "#FF0000" },
                },
              }}
            />
          </Box>
          <Box width='100%' maxWidth='500px'>
            <FormLabel>
              {intl.formatMessage({ id: "password" })} {/* 비밀번호 */}
            </FormLabel>
            <InputField
              type={isPasswordVisible ? "text" : "password"}
              placeholder={intl.formatMessage({ id: "new_wifi_pw_txt" })} /* Wi-Fi의 비밀번호를 입력해주세요. */
              autoComplete="off"
              value={wifiPassword}
              onChange={(e) => setWifiPassword(e.target.value)}
              InputLabelProps={{
                shrink: true,
                sx: {
                  "& .MuiInputLabel-asterisk": { color: "#FF0000" },
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                      edge="end"
                    >
                      {isPasswordVisible ? <Icon icon="ri:eye-off-line" /> : <Icon icon="ri:eye-line" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </InfoBox>
        <CommonButton
          text={intl.formatMessage({ id: "wifi_connection" })} /* Wi-Fi 연결 */
          background={"#FDD751"}
          color={"#333"}
          disabled={isButtonDisabled()}
          onClick={() => handleWifiConnect()}
        />
      </SignInContentsBox>
    </Stack>
  );
}

export default injectIntl(inject("authStore", "userStore")(observer(NewWifiConnect)));
