import { EventDispatcher } from "./EventDispatcher";

export type IEvent<T, A> = {
  eventName: T;
} & A;

class EventClassBase<EVENT_NAME_ENUM, EVENT_TYPE> {
  dispatcher: EventDispatcher | undefined = new EventDispatcher();

  public setEventListener(
    eventName: EVENT_NAME_ENUM,
    listener: (event: EVENT_TYPE) => void,
    filter?: any
  ) {
    if (this.dispatcher)
      this.dispatcher.set(eventName as string, listener, filter);
  }

  public addEventListener(
    eventName: EVENT_NAME_ENUM,
    listener: (event: EVENT_TYPE) => void,
    filter?: any
  ) {
    if (this.dispatcher)
      this.dispatcher.add(eventName as string, listener, filter);
    // console.log("bound", listener);
  }

  public removeEventListener(
    eventName: EVENT_NAME_ENUM,
    listener: (event: EVENT_TYPE) => void
  ) {
    if (this.dispatcher) this.dispatcher.remove(eventName as string, listener);
  }

  public removeSpecificEventListener(eventName: EVENT_NAME_ENUM) {
    if (this.dispatcher) this.dispatcher.remove(eventName as string);
  }

  public removeEventListenerAll() {
    if (this.dispatcher) this.dispatcher.offAll();
  }

  public dispose() {
    this.removeEventListenerAll();
    if (this.dispatcher) {
      this.dispatcher = undefined;
      delete this.dispatcher;
    }
  }
}

export default EventClassBase;
