import { Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ButtonStyle = styled(Button)(
  ({ theme, background, border, disabled }) => ({
    "&.MuiButtonBase-root": {
      width: "100%",
      padding: 16,
      boxSizing: "border-box",
      borderRadius: 8,
      background: background,
      border: border
        ? `1px solid ${border}`
        : disabled
          ? `1px solid #F0F1F5`
          : `1px solid ${background}`,
      "&:hover": {
        background: background,
      },
      "&.Mui-disabled": {
        background: "#F0F1F5",
        "& p": {
          color: "#C9C9CA",
        },
      },
    },
  }),
);

export const TextStyle = styled(Typography)(({ theme, color }) => ({
  "&.MuiTypography-root": {
    fontSize: "0.875rem",
    fontWeight: 400,
    lineHeight: "19.6px",
    color: color,
    textTransform: "none",
  },
}));
