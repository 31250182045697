import React from "react";

import { ReactComponent as ArrowLeftIcon } from "../../common/Images/ArrowLeftIcon.svg";
import { BackBox, IconButtonStyle } from "./Styled/CommonBackStyled";

export default function CommonBack(props) {
    const {onClick} = props;

  return (
    <BackBox>
      <IconButtonStyle disableRipple onClick={onClick}>
        <ArrowLeftIcon />
      </IconButtonStyle>
    </BackBox>
  );
}
