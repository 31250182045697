import React from "react";
import { injectIntl } from "react-intl";
import { Box, Typography } from "@mui/material";

import SecretPokoro from "../../../common/Images/SecretPokoro.png";
import { SolidBox } from "../../../common/Styled/CommonStyled";
import CommonButton from "../../CommonComponent/CommonButton";
import {
  ContentBox,
  DividerLine,
  HashtagBox,
  Root,
  TitleBox,
  ActionBox
} from "./Styled/OverallReviewComponentStyled";
import {inject, observer} from "mobx-react";
import FileShareDrawer from "./FileShareDrawer";

function OverallReviewComponent(props) {
  const {intl,reportStore, reportRef, setChartView} = props;
  const [shareOpen, setShareOpen] = React.useState(false);
  const weeklyReport = reportStore.weeklyReport;

  const toggleDrawerShare = (newShareOpen) => () => {
    setShareOpen(newShareOpen);
  };

  return (
    <Root>
      {weeklyReport !== '' &&
          <SolidBox>
            <TitleBox>
              <img src={SecretPokoro} alt={""} />
              <Typography variant="subtitle1" pl={1}>
                {weeklyReport.review &&
                    ""+weeklyReport.review.title+""
                }
              </Typography>
            </TitleBox>
            <ContentBox>
              <Typography variant="body1">
                {weeklyReport.review?.contents}
              </Typography>
              <DividerLine />
              <Typography variant="subtitle2">
                {intl.formatMessage({ id: "recommended_items" })} {/* 포코로의 추천 항목 */}
              </Typography>
              <ul>
                {weeklyReport.curriculumList?.map((curriculum, index) => (
                    <li key={index}>
                      <b style={{fontWeight: 'bold'}}>{curriculum.title}</b>: {curriculum.contents}
                    </li>
                ))}
              </ul>
              <DividerLine />
              <Typography variant="subtitle3">
                {intl.formatMessage({ id: "overall_review" })} {/* 총평 */}
              </Typography>
              <Typography style={{marginTop: 10}} variant="body1">
                {weeklyReport?.curriculumResult}
              </Typography>
              <DividerLine />
              <HashtagBox>
                {weeklyReport.tagList?.map((tag, index) => (
                    <Box key={index}>{tag}</Box>
                ))}
              </HashtagBox>
              <DividerLine />
              <ActionBox>
              <CommonButton
                  // disabled={!navigator.share}
                  id='reportShareBtn'
                  text={intl.formatMessage({ id: "share" })}
                  border={"#C9C9CA"}
                  color={"#909195"}
                  onClick={toggleDrawerShare(true)}
              />
              </ActionBox>
            </ContentBox>
          </SolidBox>
      }
      <FileShareDrawer open={shareOpen} onClose={toggleDrawerShare(false)} reportRef={reportRef} setChartView={setChartView}/>
    </Root>
  );
}

export default injectIntl(inject('reportStore')(observer(OverallReviewComponent)));
