import {
    Box,
    Divider,
    Stack
  } from "@mui/material";
  import { styled } from "@mui/material/styles";
  

  export const TitleBox = styled(Stack)(() => ({
    gap:16,
    width:'100%',
    alignItems:'center',
    '& img':{
        width:65,
        height:65,
      },
    "& .MuiTypography-h6": {
      fontWeight: 700,
      marginBottom:-8
    },
    "& ul": {
        fontSize: "0.875rem",
        display:'flex',
        gap:4,
        justifyContent:'space-between',
        width:'100%',
        "& li":{
            textAlign:'center',
            borderRadius:8,
            padding:8,
            background:'#fed751',
            flex:1,
            fontSize:'0.875rem'
        },
        "& span":{
            display:'block',
            fontSize:'1.125rem',
            fontWeight:700,
            marginTop:4
        },
        "& svg":{
            marginRight:4
        }
    }
  }));
  
  export const ContentBox = styled(Box)(() => ({
    "&>.MuiTypography-subtitle1": {
      fontWeight: 700,
      color:'#FE536B',
    },
    "& .MuiTypography-subtitle1": {
      fontWeight: 700,
      marginBottom:4,
      '& span':{
        display:'block',
        fontSize: "0.75rem",
        color: "#909195",
        fontWeight: 400,
      }
    },
    "& ul": {
      margin: 0,
      "& li": {
        marginTop: 16,
        "&.accuracy":{
            listStyle:'initial',
            marginLeft:16,
            marginTop:8
        }
      },
    },
  }));
  

  export const DividerLine = styled(Divider)(() => ({
    borderColor: "#F0F1F5",
    margin: "32px 0",
  }));
  