import {
  Drawer, Box, Button, Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";


export const DrawerStyle = styled(Drawer)(() => ({
  '& .MuiDrawer-paper':{
    padding:'58px 16px 16px',
    borderRadius:'30px 30px 0 0',
  },
  '& .MuiTypography-h5':{
    textAlign : 'center',
    fontSize:'1.125rem',
    color:'#333',
    fontWeight:700,
    marginBottom:8,
  },
  '& .MuiTypography-h6':{
    textAlign : 'center',
    fontSize:'0.875rem',
    color:'#333',
    fontWeight:400,
    marginBottom:8,
  },
}));
export const DrawerDateStyle = styled(DrawerStyle)(() => ({
  '& .MuiDrawer-paper':{
    padding:'24px 16px 16px',
  },
  '& .pickerColumn+div:last-child':{
    backgroundColor:'rgba(217, 217, 217, 0.3)',
    zIndex:-10,
    '& > div':{
      display:'none',
    },
  },
  '& .pickerItem':{
    color:'#909195',
    fontSize:'0.875rem',
  },
}));
export const Puller = styled('div')(() => ({
  width: 32,
  height: 2,
  backgroundColor: '#909195',
  borderRadius: 3,
  position: 'absolute',
  top: 24,
  left: 'calc(50% - 15px)',
}));

export const ButtonBox = styled(Box)(() => ({
  display:'grid',
  gap:8,
  '& .MuiButton-root':{
    backgroundColor:'#FBFCFE',
    color:'#909195',
    border:'1px solid #F0F1F5',
    fontSize:'0.875rem',
    '&.active, &:hover, &:focus':{
      borderColor:'#909195',
      backgroundColor:'#fff',
      color:'#333',
    },
  },
}));

export const DateButtonBox = styled(ButtonBox)(() => ({
  gridTemplateColumns:'repeat(4, 1fr)',
  marginBottom:4,
}));

export const SortButtonBox = styled(ButtonBox)(() => ({
  gridTemplateColumns:'repeat(2, 1fr)',
  marginBottom:32,
}));

export const SelectDateButton = styled(Button)(() => ({
  '&.MuiButton-root':{
    width:'100%',
    textAlign:'center',
    padding:'8px 16px',
    border:'1px solid #C9C9CA',
    borderRadius : 4,
    '& .MuiTypography-root':{
      fontSize:'0.75rem',
      color:'#909195',
      "& span":{
        color:'#333',
        margin:'0 24px',
      },
    },
  }
}));

export const PlaceholderText = styled(Typography)(() => ({
  '&.MuiTypography-root':{}
}));

export const SelectedText = styled(Typography)(() => ({
  '&.MuiTypography-root':{
    color:'#333!important',
  }
}));

