import React, { useEffect } from "react";
import { useHistory } from "react-router-dom"; // useHistory와 useLocation 추가
import { Typography, Stack } from "@mui/material";
import CommonBack from "../CommonComponent/CommonBack";
import { SubpageTitle } from "../../common/Styled/CommonStyled";
import { TitleBox, List } from "./Styled/LanguageStyled"
import { inject, observer } from "mobx-react";
import { injectIntl } from "react-intl";
import { COMMON_UTIL } from '../../common/util/common.util';
import CommonLoading from "../CommonComponent/CommonLoading";
import { usePenStatus } from "../../nativebridge/PenStatus";
import { customSendSetting } from "../../nativebridge/JsToNative";
import {
  NativeToJsBridge,
  NativeToJsEventName,
} from "../../nativebridge/NativeToJs";

function AppLanguageSetting(props) {
  const history = useHistory();
  const { intl, languageStore } = props;
  const { globalPenStatus, setGlobalPenStatus } = usePenStatus();
  const [loading, setLoading] = React.useState(false);

  const handleBack = () => {
    history.goBack();
  };

  const handleLanguageChange = (language) => {

    languageStore.setLanguage(language);
    const countryCode = COMMON_UTIL.getCountryCodeFromLanguage(language);

    setGlobalPenStatus((prevStatus) => ({
      ...prevStatus,
      deviceInfo: {
        ...prevStatus.deviceInfo, // spread the previous deviceInfo object
        lang_system: countryCode, // update lang_main
      },
    }));

    if (globalPenStatus.isConnect) {
      setLoading(true);
      const settingData = {

        messageType: "POKORO2_Settings",
        payload: {
          lang_system: countryCode,
          lang_speaking: globalPenStatus.deviceInfo.lang_speaking,
          lang_listening_main: globalPenStatus.deviceInfo.lang_listening_main,
          lang_listening_sub: globalPenStatus.deviceInfo.lang_listening_sub,
        },
      };
      customSendSetting(JSON.stringify(settingData));
    }
  };

  useEffect(() => {
    NativeToJsBridge.instance.addEventListener(
      NativeToJsEventName.onCustomSendResult,
      async (event) => {
        const jsonStr = event;
        setLoading(false);
      }
    );
    return () => {
      NativeToJsBridge.instance.removeEventListener(
        NativeToJsEventName.onCustomSendResult
      );
    };
  }, []);

  return (
    <>
      <Stack width='100%'>
        <TitleBox>
          <CommonBack onClick={handleBack} />
          <SubpageTitle sx={{ background: 'unset' }}>
            <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bolder' }}>
              <pre>{intl.formatMessage({ id: "language_settings" })}</pre> {/* 말하기 언어설정 */}
            </Typography>
            <Typography variant="body2">
              {intl.formatMessage({ id: "language_settings_description" })}{/* 앱에서 사용할 언어를 선택해주세요. */}
              <div
                style={{ color: "#909195", fontSize: "0.75rem", marginTop: 4 }}
              >
                {intl.formatMessage({ id: "system_voice_instruction" })}
                {/* (시스템 안내 음성은 한국어 선택시 한국어가 재생되며 다른 언어에 대해서는 영어로 제공 됩니다.) */}
              </div>
            </Typography>
          </SubpageTitle>
        </TitleBox>
        <Stack pt={4} gap={1}>
          <List selected={languageStore.language === 'ko'} onClick={() => handleLanguageChange("ko")}>한국어</List>
          <List selected={languageStore.language === 'en'} onClick={() => handleLanguageChange("en")}>English</List>
          <List selected={languageStore.language === 'ja'} onClick={() => handleLanguageChange("ja")}>日本語</List>
          <List selected={languageStore.language === 'zh'} onClick={() => handleLanguageChange("zh")}>简体中文</List>
        </Stack>
      </Stack>
      {
        loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.8)",
              zIndex: 9999,
            }}
          >
            <CommonLoading text={intl.formatMessage({ id: "setting_sending" })} /> {/* 설정 정보를 전송 중입니다. */}
          </div>
        )
      }
    </>
  );
}

export default injectIntl(inject('languageStore')(observer(AppLanguageSetting)));
