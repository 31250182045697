import {makeAutoObservable} from "mobx";
import dayjs from "dayjs";

const logPrefix  = "[ReportStore]"
export default class ReportStore {
    constructor(props) {
        this.reportRepository = props.reportRepository;

        this.initDate();
        this.init();

        makeAutoObservable(this);
    }

    init = () => {
        this.isDailyReportLoading = false;
        this.isWeeklyReportLoading = false;
        this.isReportDownloadLoading = false;

        this.selectedTab = 'daily';

        this.dailyReport = '';
        this.weeklyReport = '';

        this.shareData = '';
        this.shareDialogOpen = false;
        this.shareFileTitle = '';

        this.resetDate(this.calendarDate);
    }

    initDate = () => {
        this.startDatetime = dayjs().startOf('day');
        this.endDatetime = dayjs().endOf('day');
        this.calendarDate = dayjs();
    }

    get getIsLoading() {
        return this.isDailyReportLoading || this.isWeeklyReportLoading || this.isReportDownloadLoading;
    };

    setSelectedTab = (tab) => {
        this.selectedTab = tab;
    }

    resetDate = (date) => {
        const now = dayjs();
        if (this.selectedTab === 'daily') {
            this.startDatetime = date.startOf('day');
            this.endDatetime = date.endOf('day');

            this.calendarDate = date.startOf('day');
        }

        if (this.selectedTab === 'weekly') {
            if (now.startOf('isoWeek').startOf('day').toISOString() === date.startOf('isoWeek').startOf('day').toISOString()) {
                this.startDatetime = date.subtract(1, 'week').startOf('isoWeek').startOf('day');
            } else {
                this.startDatetime = date.startOf('isoWeek').startOf('day');
            }
            this.endDatetime = this.startDatetime.endOf('isoWeek').endOf('day');
        }
    }

    setDate = (date) => {
        if (this.selectedTab === 'daily') {
            this.startDatetime = date.startOf('day');
            this.endDatetime = date.endOf('day');
        }

        if (this.selectedTab === 'weekly') {
            this.startDatetime = date.startOf('isoWeek').startOf('day');
            this.endDatetime = this.startDatetime.endOf('isoWeek').endOf('day');
        }

        this.calendarDate = date.startOf('day');
    }

    setDateByDirection = (direction) => {
        if (this.selectedTab === 'daily') {
            const changeDate = direction === 'next' ? 1 : -1;
            this.startDatetime = this.startDatetime.add(changeDate, 'day');
            this.endDatetime = this.endDatetime.add(changeDate, 'day');
            this.calendarDate = this.calendarDate.add(changeDate, 'day');
        }

        if (this.selectedTab === 'weekly') {
            const changeDate = direction === 'next' ? 1 : -1;
            this.startDatetime = this.startDatetime.add(changeDate, 'week');
            this.endDatetime = this.endDatetime.add(changeDate, 'week');
            this.calendarDate = this.calendarDate.add(changeDate, 'week');
        }
    }

    setIsReportDownloadLoading = (isLoading) => {
        this.isReportDownloadLoading = isLoading;
    }

    setDeleteReport = (report) => {
        this.deleteReport = report;
    }

    setIsDetailOpen = (open) => {
        this.isDetailOpen = open;
    }

    setSearchSortDirection =  (sortDirection) => {
        this.searchSortDirection = sortDirection;
    }

    setSearchSection = (section) => {
        this.searchSection = section;
    }

    setSelectedDirectSearchType = (type) => {
        this.selectedDirectSearchType = type;
    }

    setDirectSearchStartDate = (date) => {
        if (date.hasOwnProperty('year')) {
            this.directSearchStartDate.year = date.year;
        }

        if (date.hasOwnProperty('month')) {
            this.directSearchStartDate.month = date.month;
        }

        if (date.hasOwnProperty('week')) {
            this.directSearchStartDate.week = date.week;
        }
    }

    setDirectSearchEndDate = (date) => {
        if (date.hasOwnProperty('year')) {
            this.directSearchEndDate.year = date.year;
        }

        if (date.hasOwnProperty('month')) {
            this.directSearchEndDate.month = date.month;
        }

        if (date.hasOwnProperty('week')) {
            this.directSearchEndDate.week = date.week;
        }
    }

    setStartPickerDate = (date) => {
        this.startPickerDate = date;
    }

    setEndPickerDate = (date) => {
        this.endPickerDate = date;
    }

    setTempSearchSortDirection = (direction) => {
        this.tempSearchSortDirection = direction;
    }

    setTempSearchSection = (section) => {
        this.tempSearchSection = section;
    }

    setShareData = (data) => {
        this.shareData = data;
    }

    setShareDialogOpen = (open) => {
        this.shareDialogOpen = open;
    }

    setShareFileTitle = (title) => {
        this.shareFileTitle = title;
    }

    * getDailyReport(userId, deviceId, callback) {
        try {
            console.log(logPrefix, 'Start getDailyReport.');
            this.isDailyReportLoading = true;

            const response = yield this.reportRepository.getDailyReport(userId, deviceId, this.startDatetime.toISOString(), this.endDatetime.toISOString());
            this.dailyReport = response.data;

            this.isDailyReportLoading = false;
            console.log(logPrefix, 'Finished getDailyReport.');

            callback && callback();
        } catch(e) {
            this.dailyReport = '';
            console.log(logPrefix, 'Failed getDailyReport. error: ' + e);
        } finally {
            this.isDailyReportLoading = false;
        }
    }

    * getWeeklyReport(userId, deviceId, language, callback) {
        try {
            console.log(logPrefix, 'Start getWeeklyReport.');
            this.isWeeklyReportLoading = true;

            const response = yield this.reportRepository.getWeeklyReport(userId, deviceId, this.startDatetime.toISOString(), this.endDatetime.toISOString(), language);
            this.weeklyReport = response.data;

            this.isWeeklyReportLoading = false;
            console.log(logPrefix, 'Finished getWeeklyReport.');

            callback && callback();
        } catch(e) {
            this.weeklyReport = '';
            console.log(logPrefix, 'Failed getWeeklyReport. error: ' + e);
        } finally {
            this.isWeeklyReportLoading = false;
        }
    }
}
