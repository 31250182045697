import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { observer } from "mobx-react";
import { configure } from "mobx";
import { Provider as MobxProvider } from "mobx-react";
import { ThemeProvider } from "@material-ui/core/styles";
import { stores } from "./stores";
import configureTheme from "./configureTheme";
import { BrowserRouter } from "react-router-dom";
import { IntlProvider } from "react-intl";

configure({ enforceActions: "always" });

const theme = configureTheme();

const AppWrapper = observer(() => {
    const languageStore = stores.languageStore;
    const language = languageStore.language;
    const languageData = languageStore.languageData;

    return (
        <BrowserRouter>
            <MobxProvider {...stores}>
                <IntlProvider messages={languageData} locale={language} defaultLocale={'en'}>
                    <ThemeProvider theme={theme}>
                        <App />
                    </ThemeProvider>
                </IntlProvider>
            </MobxProvider>
        </BrowserRouter>
    );
});

ReactDOM.render(<AppWrapper />, document.getElementById('root'));

// ReactDOM.render(
//   <BrowserRouter>
//     <MobxProvider {...stores}>
//         <IntlProvider messages={languageData} locale={language} defaultLocale={'en'} >
//         <ThemeProvider theme={theme}>
//           <App />
//         </ThemeProvider>
//       </IntlProvider>
//     </MobxProvider>
//   </BrowserRouter>,
//   document.getElementById('root')
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
