import React from "react";

import { ButtonStyle, TextStyle } from "./Styled/CommonButtonStyled";

export default function CommonButton(props) {
  const { id, text, background, border, color, onClick, disabled } = props;

  return (
    <ButtonStyle
      id={id}
      onClick={onClick}
      background={background}
      border={border}
      disabled={disabled}
      disableRipple
    >
      <TextStyle color={color}>{text}</TextStyle>
    </ButtonStyle>
  );
}
