import React from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';

function serviceKR(props) {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        서비스 이용약관
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제1조 (목적)
        </Typography>
        <Typography paragraph>
          이 약관은 (주)네오랩컨버전스(이하 “회사”)가 제공하는 서비스(이하 “서비스”) 의 이용과 관련하여 회사와 회원 간의 권리, 의무 및 책임 사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제2조 (용어의 정의)
        </Typography>
        <Typography paragraph>
          [1]이 약관에서 사용하는 정의는 다음과 같습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1. “회원”이란 본 약관에 동의하고 서비스이용 자격을 부여받은 자를 의미합니다."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. “서비스”란 회사가 회원에게 제공하는 모든 서비스 및 이에 부수된 제반 서비스 일체를 의미합니다."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. “계정(ID)”이란 회원의 식별과 서비스이용을 위하여 회원이 선정하고 회사 또는 제휴사가 부여하는 문자, 숫자 또는 특수문자의 조합을 의미합니다."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="4. “계정정보”란 회원의 계정, 비밀번호, 회원 번호 등을 통칭합니다."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="5. “비밀번호”란 회원이 부여받은 계정과 일치되는 회원임을 확인하고 회원의 정보 및 권익 보호를 위해 회원 자신이 선정하여 비밀로 관리하는 문자, 숫자 또는 특수문자의 조합을 의미합니다."
            />
          </ListItem>
        </List>
        <Typography paragraph>
          [2] 이 약관에서 사용하는 용어의 정의는 본 조 제1항에서 정하는 것을 제외하고는 관계 법령 및 서비스별 정책에서 정하는 바에 의하며, 이에 정하지 아니한 것은 일반적인 상 관례에 따릅니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제3조 (회사정보 등의 제공)
        </Typography>
        <Typography paragraph>
          회사는 다음 각호의 사항을 서비스내에 표시합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1. 개인정보 처리방침" />
          </ListItem>
          <ListItem>
            <ListItemText primary="2. 이용약관" />
          </ListItem>
          <ListItem>
            <ListItemText primary="3. 최종 사용자 사용권 계약" />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제4조 (약관의 명시와 개정)
        </Typography>
        <Typography paragraph>
          [1] 회사는 이 약관의 내용을 회원이 알 수 있도록 서비스내 또는 그 연결화면에 게시합니다.
        </Typography>
        <Typography paragraph>
          [2] 회사가 약관을 개정할 경우 개정약관 공지 후 개정약관의 적용에 대한 회원의 동의 여부를 확인합니다. 회사는 회원이 개정약관에 대해 동의 또는 거부의 의사표시를 하지 않으면 동의한 것으로 볼 수 있다는 내용도 함께 공지 또는 통지하며, 회원이 약관 시행일까지 거부의 의사표시를 하지 않는다면 개정약관에 동의한 것으로 간주합니다. 회원이 개정약관에 대해 동의하지 않는 경우 회사 또는 회원은 서비스이용계약을 해지할 수 있습니다.
        </Typography>
        <Typography paragraph>
          [3] 회사는 회원이 회사와 이 약관의 내용에 관하여 질의 및 응답을 할 수 있도록 조치를 취합니다.
        </Typography>
        <Typography paragraph>
          [4] 회사는 관련 법령을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제5조 (약관 외 준칙)
        </Typography>
        <Typography paragraph>
          이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「약관의 규제에 관한 법률」, 등 관련 법령 또는 상관례에 따릅니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제6조 (운영정책)
        </Typography>
        <Typography paragraph>
          [1] 회사는 약관을 적용하는 데 필요한 사항 및 약관에서 구체적 범위를 정하여 위임한 사항을 서비스운영정책(이하 “운영정책”이라 합니다.)으로 정할 수 있습니다. 이러한 운영정책은 그 내용을 회원이 알 수 있도록 서비스내 또는 그 연결화면에 게시합니다.
        </Typography>
        <Typography paragraph>
          [2] 회사는 회원의 권리의무에 중대한 변경을 가져오거나, 약관 변경에 준하는 운영 정책상 개정이 있는 경우에는 상기 제4조의 절차에 따라 이를 회원에 공지합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제7조 (이용신청의 승낙과 제한)
        </Typography>
        <Typography paragraph>
          [1] 회원이 되고자 하는 자(이하 “가입신청자”라 합니다.)는 회사의 회원가입 요청부분을 통하여 신청을 하고, 본 약관에 동의하여야 이용신청을 한 것으로 간주합니다.
        </Typography>
        <Typography paragraph>
          [2] 회사는 특별한 사유가 없는 한 가입신청자의 이용신청을 승낙함을 원칙으로 합니다. 다만, 회사는 이용신청이 다음 각호의 어느 하나에 해당하는 경우에는 승낙하지 않거나 취소할 수 있습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
            primary="1. 이용신청 방식 및 요건을 충족하지 못한 경우" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="2. 회사가 서비스를 제공하지 않은 국가에서 비정상적이거나 우회적인 방법을 통해 서비스를 이용하는 경우" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="3. 그 밖에 각호에 준하는 사유로서 승낙이 부적절하다고 판단되는 경우" 
            />
          </ListItem>
        </List>
        <Typography paragraph>
          [3] 회사는 다음 각호의 어느 하나에 해당하는 경우 그 사유가 해소될 때까지 승낙을 유보할 수 있습니다.        </Typography>
        <List>
          <ListItem>
            <ListItemText
            primary="1. 회사의 설비에 여유가 없거나, 특정 기기의 지원이 어렵거나, 기술적 장애가 있는 경우" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="2. 그 밖의 각호에 준하는 사유로서 이용신청의 승낙이 어렵다고 판단되는 경우" 
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제8조 (개인정보의 보호 및 관리)
        </Typography>
        <Typography paragraph>
          [1] 회사는 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력하며, 개인정보의 보호 및 사용에 관해서는 관련 법령 및 회사의 개인정보처리방침에 따릅니다.        
        </Typography>
        <Typography paragraph>
          [2] 회사가 제공하는 서비스 이외의 단순히 링크된 제3자의 서비스에는 회사의 개인정보처리방침이 적용되지 않습니다.       
        </Typography>
        <Typography paragraph>
          [3] 회사는 관련 법령에 따라 관련 국가기관 등의 요청이 있는 경우를 제외하고는 회원의 개인정보를 본인의 동의 없이 타인에게 제공하지 않습니다.        
        </Typography>
        <Typography paragraph>
          [4] 회사는 회원의 귀책 사유로 개인정보가 유출되어 발생한 피해에 대하여 책임을 지지 않습니다.        
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제9조 (회사의 의무)
        </Typography>
        <Typography paragraph>
          [1] 회사는 관련 법령을 준수하고, 이 약관에서 정하는 권리의 행사 및 의무의 이행을 신의에 따라 성실하게 합니다.
        </Typography>
        <Typography paragraph>
          [2] 회사는 회원이 안전하게 서비스를 이용할 수 있도록 개인정보(신용정보 포함)보호를 위해 보안시스템을 갖추어야 하며 개인정보처리방침을 공시하고 준수합니다. 회사는 이 약관 및 개인정보처리방침에서 정한 경우를 제외하고는 회원의 개인정보가 제3자에게 공개 또는 제공되지 않도록 합니다.
        </Typography>
        <Typography paragraph>
          [3] 회사는 계속적이고 안정적인 서비스의 제공을 위하여 서비스 개선을 하던 중 설비에 장애가 생기거나 데이터 등이 멸실․훼손된 때에는 천재지변, 비상사태, 현재의 기술로는 해결이 불가능한 장애나 결함 등 부득이한 사유가 없는 한 지체 없이 이를 수리 또는 복구하도록 최선의 노력을 다합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제10조 (회원의 의무)
        </Typography>
        <Typography paragraph>
          [1] 회원은 회사에서 제공하는 서비스의 이용과 관련하여 다음 각호에 해당하는 행위를 해서는 안 됩니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1. 이용신청 또는 회원 정보 변경 시 허위 사실을 기재하는 행위" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2. 다른 회원의 ID 및 비밀번호를 부정 사용하는 행위" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="3. 회사나 타인의 지식재산권 또는 초상권을 침해하는 행위" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="4. 회사나 타인의 명예를 훼손하거나 손해를 입히는 행위" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="5. 그 밖에 관련 법령에 위반되거나 선량한 풍속 기타 사회통념에 반하는 행위" 
            />
          </ListItem>
        </List>
        <Typography paragraph>
          [2] 회원의 계정 및 기기에 관한 관리 책임은 회원에게 있으며, 이를 타인이 이용하도록 하게 하여서는 안 됩니다. 계정 및 기기의 관리 부실이나 타인에게 이용을 승낙함으로 인해 발생하는 손해에 대해서 회사는 책임을 지지 않습니다.       
        </Typography>
      </Box>
      
      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제11조 (서비스의 제공 및 변경)
        </Typography>
        <Typography paragraph>
          [1] 회사는 제7조의 규정에 따라 이용신청이 승낙된 회원에게 그 즉시 서비스를 이용할 수 있도록 합니다. 다만, 일부 서비스는 회사의 필요에 따라 별도 지정된 일자부터 이용할 수 있습니다.
        </Typography>
        <Typography paragraph>
          [2] 회원은 회사가 제공하는 서비스를 이 약관, 운영정책 및 회사가 설정한 규칙에 따라 이용합니다.
        </Typography>
        <Typography paragraph>
          [3] 회사는 회원에게 서비스를 제공할 때 이 약관에 정하고 있는 서비스를 포함하여 기타 부가적인 서비스를 함께 제공할 수 있습니다.
        </Typography>
        <Typography paragraph>
          [4] 회사가 제공하는 서비스 내용은 서비스 환경의 개선을 위해 사용자마다 다를 수 있습니다.
        </Typography>
        <Typography paragraph>
          [5] 회사가 운영상, 기술상의 필요에 따라 서비스수정(패치)을 할 수 있습니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
        제12조 (정보의 제공 및 수집)
        </Typography>
        <Typography paragraph>
          회사는 다음 사항을 서비스안내 페이지나 홈페이지에 회원이 알기 쉽게 표시합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
            primary="1. 회사명" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="2. 서비스명" 
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="3. 기타 회사가 필요하다고 인정하는 사항" 
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제13조 (저작권 등의 귀속)
        </Typography>
        <Typography paragraph>
          [1] 서비스내의 회사가 제작한 콘텐츠에 대한 저작권과 기타 지식재산권은 회사의 소유입니다.        
        </Typography>
        <Typography paragraph>
          [2] 회원은 서비스를 이용하여 얻은 정보 중에서 회사 또는 제공업체에 지식재산권이 귀속된 정보를 회사 또는 제공업체의 사전 동의 없이 복제⋅전송 등의 방법(편집, 공표, 공연, 배포, 방송, 2차적 저작물 작성 등을 포함합니다. 이하 같습니다.)에 의하여 영리 목적으로 이용하거나 타인에게 이용하게 하여서는 안 됩니다. 
        </Typography>
        <Typography paragraph>
          [7] 이 조는 회사가 서비스를 유지하는 동안 유효하며, 회원 탈퇴 후에도 지속해서 적용됩니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제14조 (회원의 계약 해지 등)
        </Typography>
        <Typography paragraph>
          회원은 언제든지 회원 탈퇴를 통해 이용계약을 해지할 수 있습니다. 회원 탈퇴로 인해 회원이 서비스내에서 보유한 이용정보는 회사의 개인정보처리방침에 따라 보관기간이 만료되면 모두 삭제되어 복구가 불가능하게 됩니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          제15조 (회사의 면책)
        </Typography>
        <Typography paragraph>
          [1] 회사는 전시, 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관하여 책임을 지지 않습니다.
        </Typography>
        <Typography paragraph>
          [2] 회사는 사전에 공지된 서비스용 설비의 보수, 교체, 정기 점검, 공사 등 기타 이에 준하는 사유로 발생한 손해에 대하여 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        </Typography>
        <Typography paragraph>
          [3] 회사는 회원의 귀책으로 인한 서비스 이용의 장애에 대하여는 책임을 지지 않습니다. 다만, 회원에게 부득이하거나 정당한 사유가 있는 경우에는 그러하지 아니합니다.
        </Typography>
        <Typography paragraph>
          [4] 회원이 서비스와 관련하여 게재한 정보나 자료 등의 신뢰성, 정확성 등에 대하여 회사는 고의 또는 중대한 과실이 없는 한 책임을 지지 않습니다.
        </Typography>
        <Typography paragraph>
          [7] 회사는 회원이 사업자가 제공하는 비밀번호 등을 관리하지 않아 발생하는 제3자 결제에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        </Typography>
        <Typography paragraph>
          [8] 회원이 기기의 변경, 기기의 번호 변경, 운영체제(OS) 버전의 변경, 해외 로밍, 통신사 변경 등으로 인해 콘텐츠 전부나 일부의 기능을 이용할 수 없는 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        </Typography>
        <Typography paragraph>
          [9] 회원이 회사가 제공하는 콘텐츠나 계정정보를 삭제한 경우 회사는 이에 대해 책임을 지지 않습니다. 다만, 회사의 고의 또는 중과실에 의한 경우에는 그러하지 아니합니다.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" gutterBottom>
          제16조 (재판권 및 준거법)
        </Typography>
        <Typography paragraph>
          이 약관은 대한민국 법률에 따라 규율되고 해석됩니다. 회사와 회원 간에 발생한 분쟁으로 소송이 제기되는 경우에는 법령에 정한 절차에 따른 법원을 관할 법원으로 합니다.   
        </Typography>
      </Box>

    </Container>
  );
}

export default serviceKR;
