import React from "react";
import {Typography} from "@mui/material";

import {
  ActionBox,
  DialogBox,
} from "../CommonComponent/Styled/CommonDialogStyled";
import {
  TitleText,
  ContentText,
  ActionButton,
} from "./Styled/CommonComfirmDialogStyled";



export default function CommonComfirmDialog(props) {
  const {
    open,
    title,
    contents,
    buttonText,
    onClick,
  } = props;

  return (
    <DialogBox open={open}>
      <TitleText>
        {title}</TitleText>
      <ContentText>{contents}</ContentText>
        <ActionBox>
          <ActionButton onClick={onClick} disableRipple>
            <Typography>{buttonText}</Typography>
          </ActionButton>
        </ActionBox>

    </DialogBox>
  );
}
