import {makeAutoObservable} from "mobx";


export default class LoadingStore {
    constructor() {
        this.isLoading = false;

        makeAutoObservable(this);
    }

    setIsLoading = isLoading => this.isLoading = isLoading;
}
