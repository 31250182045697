import { Repository } from './Repository';

export default class UserRepository extends Repository {
    constructor(serverUrl) {
        super();

        this.serverUrl = serverUrl;
        this.userRequestPrefix = '/api/v1/user';
    }

    getUserDeviceList = (userId) => {
        return this.getRequestPromise('get', this.serverUrl + this.userRequestPrefix + `/${userId}/device`);
    };

    getUserWeekList = (userId, deviceId) => {
        return this.getRequestPromise('get', this.serverUrl + this.userRequestPrefix + `/${userId}/week?deviceId=${deviceId}`);
    };

    getUserDashboard = (url) => {
        return this.getRequestPromise('get', this.serverUrl + this.userRequestPrefix + `/${url}`);
    };

    getCalendar = (userId, deviceId, year, month, diffTime) => {
        return this.getRequestPromise('get', this.serverUrl + this.userRequestPrefix + `/${userId}/calendar?deviceId=${deviceId}&year=${year}&month=${month}&diffTime=${diffTime}`);
    };

    getHomeData = (userId, deviceId, startDatetime, endDatetime, diffTime) => {
        return this.getRequestPromise('get', this.serverUrl + this.userRequestPrefix + `/${userId}/home?deviceId=${deviceId}&startDatetime=${startDatetime}&endDatetime=${endDatetime}&diffTime=${diffTime}`);
    };

    updatePenName = (userId, deviceId, param) => {
        return this.getRequestPromise('put', this.serverUrl + this.userRequestPrefix + `/${userId}/device/${deviceId}`, param);
    };

    deleteUser = (userId) => {
        return this.getRequestPromise('delete', this.serverUrl + this.userRequestPrefix + `/${userId}`);
    };
}
