import React, { useState } from "react";
import { injectIntl } from "react-intl";
import { Box, Stack, Typography } from "@mui/material";
import { Header, ReportBox } from '../Styled/SubPageStyled';
import { ChartBox, KeywordList, KeywordBar, KeywordBarAct, ConversationList, ListTitle, MoreButton } from './Styled/ReportDailyStyled';
import { inject, observer } from "mobx-react";
import { Icon } from '@iconify/react';
import dayjs from "dayjs";
import { DATE_UTIL } from "../../../common/util/date.util";
import DonutChart from "../../CommonComponent/Chart/DonutChart";
import { Root } from "../../../common/Styled/CommonStyled";


function ReportDaily(props) {
  const { intl, reportStore } = props;
  const dailyReport = reportStore.dailyReport;

  /* 대화목록 3개까지만 */
  const [showAll, setShowAll] = useState(false);

  const handleToggle = () => {
    setShowAll(prevShowAll => !prevShowAll);
  };

 const visibleConversations = dailyReport.dialogList ? (showAll ? dailyReport.dialogList : dailyReport.dialogList.slice(0, 3)) : [];
 /* 대화목록 3개까지만 */


  const getDate = (datetime, duration) => {
    const startDatetime = dayjs(datetime).tz('UTC', true);
    const endDatetime = startDatetime.add(duration, 'second');

    return DATE_UTIL.getTimeZoneDate(endDatetime, DATE_UTIL.FORMAT.FULL_DATE_AMPM_DOT_FORMAT);
  }

  // 공통 차트 설정
  const commonLabelFormatter = (val, opts) => {
    const total = opts.w.config.series.reduce((a, b) => a + b, 0);
    const seriesIndex = opts.seriesIndex;
    const value = opts.w.config.series[seriesIndex];
    const percentage = Math.round((value / total) * 100);
    return percentage + '%';
  };

  // 대화 현황
  const talkOptions = {
    series: [dailyReport?.normalDialogCount ?? 0, dailyReport?.contentDialogCount ?? 0],
    labels: [
      intl.formatMessage({ id: 'general_conversation' }), 
      intl.formatMessage({ id: 'content_conversation' })
    ],
    colors: ['#4FD1D9', '#4F76D9'],
    totalLabel: intl.formatMessage({ id: 'total_conversation_count' }), /* 총 대화 횟수 */
    labelFormatter: commonLabelFormatter
  };

  // 콘텐츠 현황
  const contentsOptions = {
    series: (dailyReport?.contentList?.length > 0) ? dailyReport.contentList.map(item => item.count) : [0],
    labels: dailyReport?.contentList?.map(item => item.title) ?? [],
    colors: ['#FF5B5B', '#D0BAFF', '#D3A0FF', '#B2E0FF', '#FFF2A0', '#B2E0B2'],
    totalLabel: intl.formatMessage({ id: 'total_usage' }), /* 총 사용량 */
    labelFormatter: commonLabelFormatter
  };

  return (
    <Root>
      <ReportBox sx={{gap:'2rem'}}>
        <Header>
          <h3><Icon icon={'emojione:left-speech-bubble'} />{intl.formatMessage({ id: "conversation_status" })}</h3> {/* 대화 현황 */}
        </Header>
        <ChartBox>
          <DonutChart {...talkOptions} />
        </ChartBox>
        {dailyReport.todayKeyword && dailyReport.todayKeyword.length > 0 && (
            <Stack gap={1}>
            <Header>
              <h4>
                <Icon icon={'tabler:circle-key-filled'} />{intl.formatMessage({ id: "today_keywords" })} {/* 오늘의 키워드 */}
              </h4>
            </Header>
            <KeywordList>
              {dailyReport.todayKeyword?.map((item, index) => (
                <div key={index}>
                  <Typography sx={{marginBottom:'-4px'}}>{item.word}</Typography>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <KeywordBar>
                      <KeywordBarAct sx={{width: `${(item.count / Math.max(...dailyReport.todayKeyword.map(k => k.count))) * 100}%`}}/>
                    </KeywordBar>
                    <Typography>{item.count}</Typography>
                  </Stack>
                </div>
              ))}
            </KeywordList>
          </Stack>
        )}
        {dailyReport.todayKeyword && dailyReport.todayKeyword.length > 0 && (
          <Stack gap={1}>
            <Header>
              <h4>
                <Icon icon={'ion:list-circle-sharp'} />{intl.formatMessage({ id: "conversation_list" })} {/* 대화 목록 */}
              </h4>
            </Header>
            <Stack>
              {visibleConversations.map((item, index) => (
                <ConversationList key={index}>
                  <ListTitle>
                    {item.firstMessage}
                  </ListTitle>
                  <p>{getDate(item.startDatetime, item.duration)}</p>
                </ConversationList>
              ))}
              {dailyReport.dialogList?.length > 3 && (
                  <MoreButton variant="contained" onClick={handleToggle}>
                    {showAll ? intl.formatMessage({ id: "show_less" }) : intl.formatMessage({ id: "show_more" })}
                  </MoreButton>
              )}
            </Stack>
          </Stack>
        )}

      </ReportBox>

      <ReportBox sx={{gap:'2rem'}}>
        <Header>
          <h3>
            <Icon icon={'noto:bookmark-tabs'} />{intl.formatMessage({ id: "content_usage_status" })} {/* 컨텐츠별 사용 현황 */}
          </h3>
        </Header>
        <ChartBox>
          <DonutChart {...contentsOptions} />
        </ChartBox>
        {dailyReport.todayKeyword && dailyReport.todayKeyword.length > 0 && (
          <Stack gap={1}>
            <Header>
              <h4>
                <Icon icon={'ion:list-circle-sharp'} />{intl.formatMessage({ id: "content_list" })} {/* 컨텐츠 목록 */}
              </h4>
            </Header>
            <Stack>
              {dailyReport.contentList?.map((item, index) => (
                <ConversationList className="contents" key={index}>
                  <ListTitle>
                    {item.title}
                  </ListTitle>
                  <p>{DATE_UTIL.convertUTCToTimeZone(item.lastStartDatetime, 'HH:mm')}</p>
                </ConversationList>
              ))}
            </Stack>
          </Stack>
        )}
      </ReportBox>
    </Root>
  );
}

export default injectIntl(inject('reportStore')(observer(ReportDaily)));
