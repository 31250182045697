import React from "react";
import { injectIntl } from "react-intl";
import { inject, observer } from "mobx-react";
import { BoxIn } from "../CommonComponent/Styled/CommonLoadingStyled";
import { Typography, Stack} from "@mui/material";
import { Icon } from "@iconify/react";
import {StepNumber} from "../Connect/Styled/ConnectStyled";
import PokoroInst from "../../common/Images/PokoroInst.png";
import { LineStyle } from "../Setting/Styled/SettingStyled";

const CommonLoading = (props) => {
  const { text, intl, languageStore } = props;

  return (
    <BoxIn>
      <Stack gap={3} px={1}>
        <Stack gap={1} alignItems='flex-start'>
          <StepNumber>01</StepNumber>
          <Typography variant="body2" whiteSpace='normal'>
            {intl.formatMessage({ id: "connect_Note01" })}
          </Typography>
        </Stack>
        <Stack gap={1} alignItems='flex-start' position='relative'>
          <StepNumber>02</StepNumber>
          <Stack>
            <Typography variant="body2" whiteSpace='normal' width='95%'>
              {intl.formatMessage({ id: "connect_Note02" })}
            </Typography>
            <img src={PokoroInst} alt="Device"/>
          </Stack>
        </Stack>
        <Stack gap={1} alignItems='flex-start'>
          <StepNumber>03</StepNumber>
          <Typography variant="body2" whiteSpace='normal'>
            {intl.formatMessage({ id: "connect_Note03" })}
          </Typography>
        </Stack>
      </Stack>
      <LineStyle />
      <Icon icon='eos-icons:three-dots-loading' fontSize={40}/>
      <Typography variant="body1">
        {text}
      </Typography>
    </BoxIn>
  );
}
export default injectIntl(
  inject("languageStore")(observer(CommonLoading))
);