import {Box, Stack, Button} from "@mui/material";
import { styled } from "@mui/material/styles";

export const ChartBox = styled(Box)(() => ({
    marginBottom:'24px'
  }));

  export const KeywordList = styled(Stack)(() => ({
    padding: '8px 16px',
    gap: '4px',
    '& p': {
        fontSize:'0.875rem'
    }
  }));
  export const KeywordBar = styled(Stack)(() => ({
    position:'relative',
    height: '8px',
    width: '100%',
    background: '#F0F1F5',
    borderRadius: '100px'
  }));
  export const KeywordBarAct = styled(Stack)(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    backgroundColor: '#FFD700',
    borderRadius: '5px',
    transition: 'width 2.5s ease-out'
  }));

  export const ConversationList = styled(Stack)(() => ({
    gap: '4px',
    padding: '8px 16px',
    borderBottom:'1px solid #F0F1F5',
    '& p': {
        fontSize: '0.75rem',
        color: '#909195'
    },
    '&.contents':{
        flexDirection:'row',
        justifyContent: 'space-between',
        alignItems: 'center'
    }
  }));

  export const MoreButton = styled(Button)(() => ({
    backgroundColor:'transparent!important',
    boxShadow: 'none!important',
    color: '#717171',
    fontSize: '0.875rem'
  }));

  export const ListTitle = styled(Box)(() => ({
    fontSize:'0.875rem',
    overflow:'hidden',
    textOverflow:'ellipsis',
    display:'-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  }));