import React, { createContext, useContext, useState, ReactNode } from "react";

// 펜 상태 타입 정의
interface PenStatus {
  name: string;
  isConnect: boolean;
  batteryPercentage: number;
  storagePercentage: number;
  ConnectedAP1: APInfo;
  ConnectedAP2: APInfo;
  ConnectedAP3: APInfo;
  deviceInfo: DeviceInfo;
  serverInfo: ServerInfo;
}

interface APInfo {
  ssid: string;
  password: string;
}

interface ServerInfo {
  name: string;
  url: string;
}
// { "messageType": "C1000ToAppStatus", "payload": { "batteryPercentage": 32, "storagePercentage": 94, "ConnectedAP1": { "ssid": "Xiaomi_AX6000", "password": "Pb12345678" }, "ConnectedAP2": { "ssid": "", "password": "" }, "ConnectedAP3": { "ssid": "", "password": "" }, 
// "deviceInfo": { "name": "POKORO2", "model": "NSP-C1000", "lang_system": "KR", "lang_speaking": "US", "lang_listening_main": "KR", "lang_listening_sub": "JP", "ESP_Version": "0.0.17", "MT_Version": "0.00.0061" } } }??

interface DeviceInfo {
  name: string;
  model: string;
  lang_system: string;
  lang_speaking: string;
  lang_listening_main: string;
  lang_listening_sub: string;
  ESP_Version: string;
  MT_Version: string;
}

// Context 타입 정의
interface GlobalContextType {
  globalPenStatus: PenStatus;
  setGlobalPenStatus: (newData: PenStatus) => void;
}

// 초기 값 설정
const initialPenStatus: PenStatus = {
  name: "InitialUser",
  isConnect: false,
  batteryPercentage: 0,
  storagePercentage: 0,
  ConnectedAP1: {
    ssid: "",
    password: "",
  },
  ConnectedAP2: {
    ssid: "",
    password: "",
  },
  ConnectedAP3: {
    ssid: "",
    password: "",
  },
  deviceInfo: {
    name: "",
    model: "",
    lang_system: "",
    lang_speaking: "",
    lang_listening_main: "",
    lang_listening_sub: "",
    ESP_Version: "",
    MT_Version: "",
  },
  serverInfo: {
    name: "",
    url: "",
  },
};

// GlobalContext 생성
const GlobalContext = createContext<GlobalContextType | undefined>(undefined);

// GlobalProvider 컴포넌트
export function GlobalProvider({ children }: { children: ReactNode }) {
  const [globalPenStatus, setGlobalPenStatus] =
    useState<PenStatus>(initialPenStatus);

  return (
    <GlobalContext.Provider value={{ globalPenStatus, setGlobalPenStatus }}>
      {children}
    </GlobalContext.Provider>
  );
}

// GlobalContext 사용을 위한 커스텀 훅
export function usePenStatus(): GlobalContextType {
  const context = useContext(GlobalContext);
  if (!context) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
}
