import { inject, observer } from "mobx-react";
import { injectIntl } from "react-intl";
import { useLocation } from "react-router";
import React, { useState, useEffect, useRef } from "react";
import { Box, Tab, Stack, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import ReportDaily from "./ReportDaily";
import ReportWeekly from "./ReportWeekly";
import BottomNavigation from "../BottomNavigation";
/* styled */
import { CalendarBox } from "../../../common/Styled/CommonStyled";
import { TitleStyle, CustomTabs } from "../../../common/Styled/CommonStyled";
import { DateTab } from "../Pronunciation/Styled/PronunciationDataStyled";
import { Root } from "../../../common/Styled/CommonStyled";

/* calendar */
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import advancedFormat from "dayjs/plugin/advancedFormat";
import "dayjs/locale/ko";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
import { isReport } from "../../../nativebridge/JsToNative";

import SecretPokoro from "../../../common/Images/SecretPokoro.png"

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.locale("ko");

function Report(props) {
  const location = useLocation();
  const {
    intl,
    authStore,
    userStore,
    loadingStore,
    reportStore,
    languageStore,
    homeStore,
    pronunciationStore,
  } = props;
  const { selectedTab } = reportStore;
  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef();

  useEffect(() => {
    isReport(true);
    return () => {
      isReport(false);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    reportStore.getDailyReport(
      authStore.loginUser.id,
      userStore.selectedDevice.deviceId
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event, newTab) => {
    reportStore.setSelectedTab(newTab);
    reportStore.resetDate(reportStore.calendarDate);

    if (reportStore.selectedTab === "daily") {
      reportStore.getDailyReport(
        authStore.loginUser.id,
        userStore.selectedDevice.deviceId
      );
    } else {
      reportStore.getWeeklyReport(
        authStore.loginUser.id,
        userStore.selectedDevice.deviceId,
        languageStore.getLanguageString
      );
    }
  };

  const handleArrowClick = (direction) => {
    reportStore.setDateByDirection(direction);
    if (selectedTab === "daily") {
      reportStore.getDailyReport(
        authStore.loginUser.id,
        userStore.selectedDevice.deviceId
      );
    } else {
      reportStore.getWeeklyReport(
        authStore.loginUser.id,
        userStore.selectedDevice.deviceId,
        languageStore.getLanguageString
      );
    }

    homeStore.setSelectedCalendarDate(reportStore.calendarDate.toDate());
    pronunciationStore.setDate(dayjs(reportStore.calendarDate));
  };

  const handleDateChange = (date) => {
    if (date) {
      setShowCalendar(false);
      document.body.style.overflow = "auto";

      reportStore.setDate(dayjs(date));
      if (selectedTab === "daily") {
        reportStore.getDailyReport(
          authStore.loginUser.id,
          userStore.selectedDevice.deviceId
        );
      } else {
        reportStore.getWeeklyReport(
          authStore.loginUser.id,
          userStore.selectedDevice.deviceId,
          languageStore.getLanguageString
        );
      }

      homeStore.setSelectedCalendarDate(date);
      pronunciationStore.setDate(dayjs(reportStore.calendarDate));
    }
  };

  const getDateDisplay = () => {
    if (selectedTab === "daily") {
      return reportStore.startDatetime
        .locale(languageStore.language)
        .format("YYYY. MM. DD (ddd)");
    }

    if (selectedTab === "weekly") {
      return `${reportStore.startDatetime.format(
        "YYYY. MM. DD"
      )} ~ ${reportStore.endDatetime.format("YYYY. MM. DD")}`;
    }

    return "";
  };

  const isNextButtonDisabled = () => {
    const now = dayjs();
    if (selectedTab === "daily") {
      return reportStore.startDatetime.isSame(now, "day");
    } else if (selectedTab === "weekly") {
      return reportStore.startDatetime.isSame(
        now.subtract(1, "week").startOf("isoWeek"),
        "day"
      );
    }
    return false;
  };

  /* 캘린더 외부 클릭 시 닫힘 */
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (calendarRef.current && !calendarRef.current.contains(event.target)) {
        setShowCalendar(false);
        document.body.style.overflow = "auto";
      }
    };
    if (showCalendar) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, [showCalendar]);

  useEffect(() => {
    loadingStore.setIsLoading(reportStore.getIsLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportStore.getIsLoading]);

  const [tabValue, setTabValue] = useState("report");

  // 데이터 있는 날짜 표시
  const tileContent = () => {{
      return (
        <span
          aria-label="데이터 있는 날짜 표시"
          style={{
            width: '4px',
            height: '4px',
            background: '#FF993C',
            borderRadius: '100px',
            position: 'absolute',
            bottom: 6
          }}
        >
        </span>
      );
    }
    return null;
  };

  return (
    <Root>
      {tabValue === "report" && (
        <>
          <TitleStyle>
            <Icon icon="noto:bar-chart" />{" "}
            {intl.formatMessage({ id: "report_title" })}
          </TitleStyle>

          <Box>
            <CustomTabs
              value={selectedTab}
              onChange={handleTabChange}
              centered
              variant="fullWidth"
            >
              <Tab
                value="daily"
                label={intl.formatMessage({ id: "daily_report" })}
              />
              <Tab
                value="weekly"
                label={intl.formatMessage({ id: "weekly_report" })}
              />
            </CustomTabs>
          </Box>

          <Stack gap={1} mb={9} sx={{ background: "#fbfbfb" }}>
            <DateTab pt={1}>
              <IconButton
                size="large"
                onClick={() => handleArrowClick("previous")}
              >
                <Icon icon={"iconamoon:arrow-left-2-thin"} />
              </IconButton>

              <span
                onClick={() => setShowCalendar(true)}
                style={{ cursor: "pointer" }}
              >
                {getDateDisplay()}
              </span>

              <IconButton
                size="large"
                onClick={() => handleArrowClick("next")}
                disabled={isNextButtonDisabled()}
              >
                <Icon icon={"iconamoon:arrow-right-2-thin"} />
              </IconButton>

              {showCalendar && (
                <>
                  <div
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      zIndex: 105,
                    }}
                  />

                  <CalendarBox
                    ref={calendarRef}
                    className="data"
                    style={{ zIndex: 110 }}
                  >
                    <Calendar
                      locale={languageStore.language}
                      onChange={handleDateChange}
                      value={reportStore.calendarDate.toDate()}
                      formatDay={(locale, date) => moment(date).format("D")}
                      /* tileContent={tileContent} */
                      calendarType="gregory"
                      showNeighboringMonth={false}
                      tileDisabled={({ activeStartDate, date, view }) => {
                        const now = new Date();
                        const today = new Date(
                          now.getFullYear(),
                          now.getMonth(),
                          now.getDate(),
                          0,
                          0,
                          0
                        );

                        if (selectedTab === "weekly") {
                          return (
                            dayjs(date).add(7, "day").startOf("isoWeek") >
                            dayjs(today).startOf("isoWeek")
                          );
                        } else {
                          return date > today;
                        }
                      }}
                    />
                  </CalendarBox>
                </>
              )}
            </DateTab>
            {selectedTab === "daily" ? <ReportDaily /> : <ReportWeekly />}
          </Stack>
        </>
      )}
      <BottomNavigation
        value={tabValue}
        handleChangeTab={(event, newValue) => setTabValue(newValue)}
      />
    </Root>
  );
}

export default injectIntl(
  inject(
    "authStore",
    "userStore",
    "loadingStore",
    "reportStore",
    "languageStore",
    "homeStore",
    "pronunciationStore",
  )(observer(Report))
);
