import { Box, Stack} from "@mui/material";
import { styled } from "@mui/material/styles"
import { CardBox } from "../../../../common/Styled/CommonStyled";

 export const ScoreSection = styled(Stack)(() => ({
    justifyContent: 'space-between',
    gap: '1.5rem',
    '& p': {
        color: 'gray',
        fontSize: '0.8rem',
        width: '100%'
      },
  }));
  
  export const FeedbackSection = styled(Box)(() => ({
    display: 'flex',
    gap:'1rem'
  }));
  
  export const Feedback = styled(Stack)(() => ({
    flex: 1,
    gap: '0.5rem',
    '& h4':{
        fontWeight:'Medium',
        display:'flex',
        gap: '4px',
        alignItems:'center'
    }
  }));
  export const CardBoxStyle = styled(CardBox)(() => ({
    display: "flex",
    flexDirection: "column",
    gap: 8,
    margin: 0,
    borderRadius: 8,
    padding:'0.5rem',
    '& .score':{
        display: 'flex',
        justifyContent: 'space-between',
        color: '#FE536B',
        '&.well':{
            color:'#868AFF'
        }
    },
    '& .words':{
        color: '#909195',
        fontSize: '0.75rem'
    }
  }));
  export const TipsSection = styled(Box)(() => ({
    backgroundColor: '#FFFCF0',
    padding: 16,
  }));
