import React from "react";
import {
  Typography,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  List,
  ListItem,
  ListItemText,
  Box,
} from '@mui/material';

function privacyKR(props) {
  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        개인정보 처리방침
      </Typography>
      <Typography paragraph>
        (주)네오랩컨버전스(이하 “회사”)는 이용자의 개인정보보호를 중요시하며 개인정보보호 관련 법률을 준수하고 있습니다. 회사는 이용자의 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보처리방침을 수립·공개합니다.
      </Typography>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          1. 수집하는 개인정보의 항목 및 수집방법
        </Typography>
        <Typography paragraph>
          회사는 서비스 제공 및 운영을 위하여 다음의 개인정보를 수집·이용하고 있습니다.
        </Typography>
       <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" bgcolor="#CCCCCC">서비스</TableCell>
                <TableCell align="center" bgcolor="#CCCCCC">수집 및 이용목적</TableCell>
                <TableCell align="center" bgcolor="#CCCCCC">수집 및 이용항목</TableCell>
                <TableCell align="center" bgcolor="#CCCCCC">구분</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">서비스(Pokoro)</TableCell>
                <TableCell align="center">서비스 제공 및 운영</TableCell>
                <TableCell align="center">회원 ID</TableCell>
                <TableCell align="center">필수</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">포코로2 디바이스 ID</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center"></TableCell>
                <TableCell align="center">포코로2 응답 내용</TableCell>
                <TableCell align="center"></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          2. 개인정보의 처리목적
        </Typography>
        <Typography paragraph>
          회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보 보호법에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.
        </Typography>
        <Typography paragraph>
          이용자의 서비스 이용 과정에서 발생하는 데이터(발화 내용)를 포함하는 비개인정보는 서비스 이용 환경 분석 및 개선, 고객 지원 서비스 제공 및 운영 등의 목적을 위하여 이용될 수 있습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1) 서비스 제공 및 운영"
              secondary={
                <ul>
                  <li>이용자 관리(이용자 식별, 서비스 이용 의사 확인 등)</li>
                  <li>서비스 이용 데이터를 분석한 통계 데이터의 제공</li>
                </ul>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2) 고객지원 서비스 제공 및 운영"
              secondary={
                <ul>
                  <li>질의응답 등 고객지원 서비스 제공</li>
                  <li>서비스 이슈 대응 및 안정성 확보</li>
                </ul>
              }
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          3. 개인정보의 제3자 제공
        </Typography>
        <Typography paragraph>
          회사는 개인정보를 ‘2. 개인정보의 처리목적’에서 고지한 범위 내에서 사용하며, 원칙적으로 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 개인정보를 외부에 공개하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1) 이용자로부터 별도의 동의를 받은 경우" />
          </ListItem>
          <ListItem>
            <ListItemText primary="2) 법률에 특별한 규정이 있는 경우" />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          4. 개인정보의 보유 기간
        </Typography>
        <Typography paragraph>
          이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기 또는 분리보관합니다. 단, 다음과 같은 사유에 해당하는 경우 개인정보를 일정한 기간 동안 보관 및 이용합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText 
              primary="1) 이용자 불만 및 분쟁 해결"
              secondary="이용자가 회원을 탈퇴하는 경우 또는 개인정보 삭제를 요청하는 경우, 서비스 제공 및 운영 등을 목적으로 수집한 개인정보는 이용자 불만 및 분쟁 해결을 위하여 회원 탈퇴일 또는 요청일로부터 15일간 보관 후 파기 또는 분리보관합니다."
              />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2) 부정 가입 및 이용 방지" 
              secondary="서비스 이용 중 발생한 부정 이용 기록은 부정 가입 및 이용 방지를 위하여 회원 탈퇴일로부터 5년간 보관 후 파기 또는 분리보관합니다. 이 외에도 관계법령의 규정에 의하여 수집 및 이용목적 달성 후에도 보존할 필요가 있는 경우, 회사는 다음과 같이 관계법령에서 정한 일정한 기간 동안 이용자의 정보를 보관합니다."
              />
          </ListItem>
        </List>
       <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" bgcolor="#CCCCCC">구분</TableCell>
                <TableCell align="center" bgcolor="#CCCCCC">근거</TableCell>
                <TableCell align="center" bgcolor="#CCCCCC">보존 기간</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="center">접속에 관한 기록보존</TableCell>
                <TableCell align="center">통신비밀보호법</TableCell>
                <TableCell align="center">3개월</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">표시·광고에 관한 기록</TableCell>
                <TableCell align="center">전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell align="center">6개월</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">소비자의 불만 또는 분쟁처리에 관한 기록</TableCell>
                <TableCell align="center">전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell align="center">3년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">계약 또는 청약철회 등에 관한 기록</TableCell>
                <TableCell align="center">전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell align="center">5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">대금결제 및 재화 등의 공급에 관한 기록</TableCell>
                <TableCell align="center">전자상거래 등에서의 소비자 보호에 관한 법률</TableCell>
                <TableCell align="center">5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">세법이 규정하는 처리에 관한 기록</TableCell>
                <TableCell align="center">국세기본법, 소득세법</TableCell>
                <TableCell align="center">5년</TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center">전자금융 거래에 관한 기록</TableCell>
                <TableCell align="center">전자금융거래법</TableCell>
                <TableCell align="center">5년</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          5. 개인정보의 파기절차 및 방법
        </Typography>
        <Typography paragraph>
          회사는 원칙적으로 ‘4. 개인정보의 보유 기간’에 명시된 보유 기간이 경과되었을 때에는 지체없이 개인정보를 파기 또는 별도로 분리하여 보관합니다. 파기하는 경우, 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
        </Typography>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          6. 이용자·법정대리인의 권리 및 그 행사방법
        </Typography>
        <Typography paragraph>
          회사는 개인정보를 ‘2. 개인정보의 처리목적’에서 고지한 범위 내에서 사용하며, 원칙적으로 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 개인정보를 외부에 공개하지 않습니다. 다만, 다음의 경우에는 예외로 합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText primary="1) 이용자는 언제든지 개인정보 열람, 정정, 삭제, 처리 정지 요구 등의 권리를 행사할 수 있습니다." />
          </ListItem>
          <ListItem>
            <ListItemText primary="2) 권리 행사는 회사에 대해 서면, 전자우편, 모사전송(FAX) 등을 통하여 하실 수 있으며, 회사는 이에 대해 지체 없이 조치하겠습니다." />
          </ListItem>
          <ListItem>
            <ListItemText primary="3) 권리 행사는 이용자의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출하셔야 합니다." />
          </ListItem>
          <ListItem>
            <ListItemText primary="4) 개인정보 열람 및 처리 정지 요구는 개인정보 보호법 제35조 제5항, 제37조 제2항에 의하여 이용자의 권리가 제한 될 수 있습니다." />
          </ListItem>
          <ListItem>
            <ListItemText primary="5) 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다." />
          </ListItem>
          <ListItem>
            <ListItemText primary="6) 회사는 이용자 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다." />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          7. 개인정보의 기술적·관리적 보호 대책
        </Typography>
        <Typography paragraph>
        회사는 이용자들의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출유출, 위·변조 또는 훼손되지 않도록 다음과 같은 기술적, 관리적 보호대책을 강구하고 있습니다. 회사는 개인정보의 완전한 보호를 보증하지 않으며, 이용자는 회사에 개인정보를 제공함으로써 이에 대하여 동의합니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
            primary="1) 관리적 보호조치" 
            secondary="내부관리계획의 수립·시행, 정기적 직원 교육 실시, 수탁업체에 대한 보안 준수 여부 감독"
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="2) 기술적 보호조치" 
            secondary="개인정보처리시스템에 대한 접근권한 관리, 개인정보의 암호화, 보안 프로그램의 설치·운영"
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="3) 물리적 보호조치" 
            secondary="전산실, 자료보관실 등의 접근통제"
            />
          </ListItem>
          <ListItem>
            <ListItemText
            primary="4) 개인정보보호전담기구의 운영" 
            secondary={
              <ul>
                <li>회사는 사내 개인정보보호전담기구 등을 통하여 본 개인정보처리방침 이행사항 및 담당자의 준수여부를 점검하여 문제가 발견될 경우 즉시 시정할 수 있도록 노력하고 있습니다.</li>
                <li>단, 이용자 본인의 부주의나 인터넷상의 문제로 비밀번호, 플랫폼 ID, 닉네임, 이메일 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.</li>
              </ul>
            }
            />
          </ListItem>

        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          8. 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항
        </Typography>
        <Typography paragraph>
          회사는 이용자들에게 맞춤형 서비스 제공 및 서비스 이용 형태 분석 등을 목적으로 개인정보를 자동 수집하고 이용할 수 있습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="1) 맞춤형 서비스 제공"
              secondary={
                <>
                  <Typography paragraph>
                    회사는 이용자들이 회사의 서비스를 어떻게 이용하는지 분석 및 평가하고 수요를 파악하며, 서비스와 제품을 개선하고 맞춤화하여 효율적인 서비스를 제공하기 위하여 이용자의 정보를 수시로 저장하고 찾아내는 ‘쿠키(cookie)’를 운용합니다. ‘쿠키’란 회사의 웹 사이트를 운영하는 데 이용되는 서버가 이용자의 브라우저에 보내는 아주 작은 텍스트 파일로서 이용자의 컴퓨터 하드디스크에 저장됩니다.
                  </Typography>
                  <Typography paragraph>
                    이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 웹 사이트에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 단, 귀하께서 쿠키 설치를 거부하였을 경우 서비스 사용에 어려움이 있을 수 있습니다.
                  </Typography>
                  <Box mb={2}>
                    <Typography variant="subtitle1">쿠키 설정 거부 방법(웹 브라우저)</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" bgcolor="#CCCCCC"><strong>Internet Explorer에서 설정하기</strong></TableCell>
                            <TableCell align="center" bgcolor="#CCCCCC"><strong>Chrome에서 설정하기</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <List>
                                <ListItem>① [도구] 메뉴에서 [인터넷 옵션]을 선택합니다.</ListItem>
                                <ListItem>② [개인정보] 탭의 [고급]을 선택합니다.</ListItem>
                                <ListItem>③ [쿠키를 처리하는 방법]에서 ‘차단’합니다.</ListItem>
                              </List>
                            </TableCell>
                            <TableCell>
                              <List>
                                <ListItem>① 오른쪽 상단에서 더보기 메뉴의 [설정]을 선택합니다.</ListItem>
                                <ListItem>② 왼쪽 상단 메뉴의 [개인 정보 보호 및 보안]에서 [사이트 설정]을 선택합니다.</ListItem>
                                <ListItem>③ [쿠키 및 사이트 데이터]를 선택한 후, [사이트에서 쿠키 데이터 저장 및 읽기 허용]을 ‘사용중지’합니다.</ListItem>
                              </List>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                  <Box mb={2}>
                    <Typography variant="subtitle1">쿠키 설정 거부 방법(모바일 브라우저)</Typography>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell align="center" bgcolor="#CCCCCC"><strong>Safari에서 설정하기</strong></TableCell>
                            <TableCell align="center" bgcolor="#CCCCCC"><strong>Chrome에서 설정하기</strong></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              <List>
                                <ListItem>① [설정] 메뉴에서 [Safari]를 선택합니다.</ListItem>
                                <ListItem>② [개인 정보 보호 및 보안]에서 ‘모든 쿠키 차단’을 활성화합니다.</ListItem>
                                <ListItem>③ [방문 기록 및 웹 사이트 데이터 지우기]를 선택한 후, ‘방문 기록 및 웹 사이트 데이터 지우기’를 선택합니다.</ListItem>
                              </List>
                            </TableCell>
                            <TableCell>
                              <List>
                                <ListItem>① 왼쪽 하단에서 [더보기(···)] 메뉴의 [설정]을 선택합니다.</ListItem>
                                <ListItem>② [동기화 및 Google 서비스]에서 ‘검색어 및 URL 자동 완성’ 및 ‘검색 및 탐색 기능 개선’을 ‘비활성화’합니다.</ListItem>
                              </List>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </>
              }
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="2) 서비스 이용 형태 분석"
              secondary={
                <>
                  <Typography paragraph>
                    회사는 이용자에게 더 나은 서비스를 제공하기 위한 목적으로 Google, Inc.(이하 “Google”)이 제공하는 웹 분석 서비스인 Google Analytics를 사용하여 쿠키(Cookie)를 통해 이용자들이 회사의 서비스를 어떻게 이용하는지 수집, 분석 및 평가하고 수요를 파악합니다. 그럼에도 불구하고 이용자는 쿠키 설정을 거부하거나, 다음의 부가기능 설치 등을 통해 Google Analytics의 쿠키 이용을 거부할 수 있습니다.
                  </Typography>
                  <Typography>Google Analytics 차단 방법: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout</a></Typography>
                </>
              }
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          9. 개인정보 보호책임자의 연락처
        </Typography>
        <Typography paragraph>
          회사는 개인정보 관련 문의 및 불만 처리 등을 위하여 다음과 같이 개인정보 보호책임자를 지정하고 있습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="개인정보 보호책임자"
              secondary={
                <>
                  <Typography>이름: 국수열</Typography>
                  <Typography>메일: <a href="mailto:contact@neolab.net">contact@neolab.net</a></Typography>
                </>
              }
            />
          </ListItem>
        </List>
        <Typography paragraph>
          회사의 자체적인 개인정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하신 경우에는 다음의 기관으로 문의하실 수 있습니다.
        </Typography>
        <List>
          <ListItem>
            <ListItemText
              primary="개인정보침해신고센터"
              secondary="privacy.kisa.or.kr / 국번없이 118"
            />
          </ListItem>
        </List>
      </Box>

      <Box mb={4}>
        <Typography variant="h5" gutterBottom>
          10. 개인정보 처리방침의 공개와 개정
        </Typography>
        <Typography paragraph>
          회사는 본 개인정보처리방침을 변경할 경우 시행일자 및 변경내용을 시행일 7일 이전에 웹 사이트를 통해 공지할 것입니다.
        </Typography>
      </Box>

      <Box>
        <Typography variant="h5" gutterBottom>
          부 칙
        </Typography>
        <Typography paragraph>
          본 개인정보처리방침은 2024년 02월 01일부터 시행합니다.
        </Typography>
      </Box>
    </Container>
  );
}

export default privacyKR;
