import React from "react";


function privacyEN(props) {
  return (
    <></>
  );
}

export default privacyEN;
