import { Badge, Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { CardBox } from "../../../../common/Styled/CommonStyled";

export const Root = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: 16,
  "& .MuiTypography-root": {
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    wordBreak: "break-all",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": "1",
  },
}));
export const CardBoxStyle = styled(CardBox)(() => ({
  display: "flex",
  gap: 16,
  padding: "24px 8px",
  margin: 0,
  borderRadius: 24,
  "&:hover": {
    cursor: "pointer",
  },
}));
export const BadgeStyle = styled(Badge)(() => ({
  "& .MuiAvatar-root": {
    width: 30,
    height: 30,
  },
  "& .MuiBadge-badge": {
    backgroundColor: "#FE536B",
    minWidth: 6,
    height: 6,
    left: 0,
    right: "unset",
  },
}));
export const TitleBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  marginBottom: 8,
  justifyContent:'space-between'
}));

export const ListTitle = styled(Typography)(() => ({
  width: "78%",
  fontSize: "1rem",
  fontWeight: 700,
  color: "#333",
}));
export const DateTextStyle = styled(Typography)(() => ({
  fontSize: "0.75rem",
  color: "#909195",
  minWidth:'15%',
  maxWidth:'40%',
  textAlign:'right'
}));
export const ListContent = styled(Typography)(() => ({
  fontSize: "0.875rem",
}));

export const PaginationStyle = styled(Box)(() => ({
  display:'flex',
  justifyContent:'center',
  "& .MuiPagination-ul": {
    justifyContent: "space-between",
  },
  "& .MuiPaginationItem-root": {
    fontSize: "0.75rem",
    color: "#909195",
  },
  "& .MuiPaginationItem-root.Mui-selected": {
    color: "#fff",
    backgroundColor: "#FDD751",
  },
}));
