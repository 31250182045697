import React from "react";

import { Typography } from "@mui/material";

import {
  ActionBox,
  DialogBox,
  LeftButtonStyle,
  RightButtonStyle,
  TextStyle,
  TitleText,
} from "./Styled/CommonDialogStyled";

export default function CommonDialog(props) {
  const {
    open,
    title,
    subText,
    subTextColor,
    action = true,
    LeftButtonText,
    RightButtonText,
    childrenBox,
    onClick1,
    onClick2,
  } = props;

  return (
    <DialogBox open={open}>
      <TitleText>{title}</TitleText>
      <TextStyle color={subTextColor}>{subText}</TextStyle>
      {childrenBox && <div>{childrenBox}</div>}
      {action && (
        <ActionBox>
          <LeftButtonStyle onClick={onClick1} disableRipple>
            <Typography>{LeftButtonText}</Typography>
          </LeftButtonStyle>
          <RightButtonStyle onClick={onClick2} disableRipple>
            <Typography>{RightButtonText}</Typography>
          </RightButtonStyle>
        </ActionBox>
      )}
    </DialogBox>
  );
}
