import { BottomNavigation } from "@mui/material";
import { styled } from "@mui/material/styles";

export const Root = styled(BottomNavigation)(() => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  width: "100vw",
  height: 75,
  padding: '1rem',
  borderTop: "1px solid #F0F1F5",
  zIndex: 100,
  justifyContent:'space-between',
  boxSizing:'border-box',
  '& .MuiBottomNavigationAction-root':{
    minWidth: '50px',
    width:'50px',
    opacity: .2,
    padding: 0,
    color: "#333",
    "&.Mui-selected": {
      opacity: 1,
    },
    '& svg':{
      width:24,
      height:24,
      color:'#333'
    },
  },
  "& .MuiBottomNavigationAction-label": {
    marginTop: 3,
    fontSize: "0.75rem",
    transition: "none",
    color: "#333",
    "&.Mui-selected": {
      fontSize: "0.75rem",
      opacity: 1,
    },
  },
}));
